@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

body {
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    margin: 0 auto;
    box-sizing: border-box;
    line-height: 1.234;
}

a {
    color: currentColor;
}

.navigation li {
    vertical-align: middle;
}

.big-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
    z-index: 1;
    border-radius: 5px;
    margin: 0;
    padding: 0;
}

.small-frame {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 250px;
    height: 150px;
    background-color: #f1f1f1;
    z-index: 2;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

.video-top-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.video-top-bar .user-name {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    line-height: 1.4;
    display: flex;
    align-items: center;
}

.video-top-bar .user-name .user-mute-icon {
    max-width: 14px;
    flex: 0 0 14px;
    margin-left: 5px;
    display: block;
}

.small-frame .user-name {
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #fff;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    line-height: 1.4;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.small-frame .user-name .user-mute-icon {
    max-width: 12px;
    flex: 0 0 12px;
    margin-left: 5px;
    display: block;
}

.video-top-bar .topbar-right-action {
    display: flex;
    align-items: center;
    gap: 15px;
}

.video-top-bar .topbar-right-action .action-btn {
    background-color: transparent;
    border: 0;
    padding: 0;
    height: 14px;
    width: 14px;
    cursor: pointer;
}

.video-top-bar .topbar-right-action .action-btn.mute-btn {
    height: 16px;
    width: 16px;
}

.video-top-bar .topbar-right-action .action-btn img {
    max-width: 100%;
    height: auto;
    display: block;
}

.video-top-bar .topbar-right-action .devider-vertial {
    height: 20px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.30);
}

.video-top-bar .topbar-right-action .call-time {
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 2px;
    line-height: 1.2;
}

.video-top-bar .topbar-right-action .end-call-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    line-height: 1.2;
    border: 0px;
    padding: 6px 8px;
    background-color: #F15B5B;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 767px) {
    .video-top-bar .topbar-right-action .end-call-btn {
        background-color: transparent;
    }

    .video-top-bar .topbar-right-action .end-call-btn .btn-text {
        display: none;
    }
}

.video-top-bar .topbar-right-action .end-call-btn:hover {
    background-color: #d24b4b;
}

.video-top-bar .topbar-right-action .end-call-btn img {
    max-width: 20px;
}

.navigation li.user-avatar * {
    vertical-align: middle;
}

.navigation li.user-avatar img {
    margin-left: 10px;
}

.login .hero-find-carer {
    text-align: center;
}

.login .hero-find-carer img {
    width: 100%;
    max-width: 420px;
}

@media (max-width: 768px) {
    .login {
        flex-direction: column;
    }
}

@media (min-width: 768px) {
    .login-form {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    }
}

@media (min-width: 1024px) {
    .login-form {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
    }
}

.login-form h2 {
    margin-bottom: 50px;
}

.login-form form {
    position: relative;
}

.login-form label {
    font-size: 22px;
    font-weight: 600;
}

.login-form input[type=text],
.login-form input[type=password] {
    width: 100%;
    border: none;
    display: block;
    margin-top: 25px;
    margin-bottom: 25px;
}

.login-form input[type=submit] {
    padding-left: 60px;
    padding-right: 60px;
}

.login-form a {
    font-weight: normal;
}

.login-form a.forgot-password {
    right: 0;
    bottom: 0;
    position: absolute;
}

.login-form p {
    font-size: 15px;
    margin-top: 30px;
}

.spacey-text {
    font-size: 22px;
    margin-top: 60px;
    font-weight: 200;
    line-height: 50px;
}

.spacey-text a {
    font-weight: normal;
}

.about {
    font-size: 19px;
    font-weight: 300;
    line-height: 23px;
}

.about h1 {
    margin-top: 75px;
    line-height: 40px;
    text-align: center;
}

.about>h2 {
    max-width: 800px;
    margin: 30px auto;
    line-height: 40px;
    text-align: center;
}

.about img {
    width: 100%;
    display: block;
}

.dashboard {
    margin-top: 200px;
}

@media (max-width: 600px) {
    .dashboard {
        margin-top: 100px;
    }
}

.dashboard h1 {
    text-align: center;
}

.dashboard h1 small {
    display: block;
    font-weight: 400;
    line-height: 40px;
    padding-top: 20px;
}

.dashboard .tiles {
    text-align: center;
    padding: 50px 0 100px;
}

@media (max-width: 600px) {
    .dashboard .tiles {
        margin-top: 100px;
    }
}

.dashboard .tiles>a {
    width: 100%;
    margin: 10px;
    padding: 70px 0;
    max-width: 310px;
    font-weight: 400;
    border-radius: 20px;
    display: inline-block;
    background-color: #FBB157;
}

.create-client h1,
.create-job h1 {
    text-align: center;
}

.create-client h1 small,
.create-job h1 small {
    display: block;
    font-size: 70%;
    font-weight: 300;
    padding-top: 20px;
    line-height: 30px;
}

.two-columns {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .two-columns {
        flex-direction: column;
    }
}

.two-columns div {
    flex: 0 0 50%;
    padding: 50px 40px;
}

.two-columns div.shaded {
    color: white;
    position: relative;
    background-color: #3B465C;
}

.two-columns div.shaded.overlap:before {
    left: 0;
    top: -50px;
    width: 100%;
    content: '';
    height: 50px;
    position: absolute;
    background-color: #3B465C;
}

@media (max-width: 768px) {
    .two-columns div.shaded.overlap:before {
        display: none;
    }
}

@media (max-width: 768px) {
    .two-columns div.shaded {
        top: 0;
    }
}

.two-columns div.shaded p {
    text-align: left;
}

@media (max-width: 768px) {
    .two-columns div {
        padding: 30px;
    }

    .two-columns div:not(.shaded):last-child {
        padding-bottom: 0;
    }
}

.hero-training {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    display: block;
    font-weight: 500;
    position: relative;
    text-align: center;
}

.hero-training h1 {
    color: white;
}

.hero-training h1 small {
    display: block;
    font-size: 60%;
    font-weight: 300;
    margin: 20px auto;
}

.hero-training h1 span {
    display: block;
    font-size: 75%;
}

.hero-training h1 img {
    padding-top: 10px;
}

.shaded {
    color: white;
    padding: 40px 0 80px;
    background-color: #3B465C;
}

.shaded p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.accordion-content .split {
    display: flex;
    justify-content: space-between;
}

.accordion-content .split .btn {
    border: none;
}

@media (max-width: 768px) {
    .accordion-content .split {
        flex-direction: column;
    }
}

.accordion-content .split div:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    text-align: center;
}

.accordion-content .split div:first-child img {
    width: 100%;
}

@media (max-width: 768px) {
    .accordion-content .split div:first-child img {
        max-width: 300px;
    }
}

.accordion-content .split div:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
}

.course-completed {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 0 80px;
}

.course-completed h2 {
    text-align: center;
    padding-bottom: 40px;
}

.course-completed p,
.course-completed ul {
    font-weight: 300;
}

.popup-holder {
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    display: none;
    position: fixed;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-holder.shown {
    display: flex;
}

.popup-holder .login-popup {
    max-width: 400px;
    border-radius: 5%;
    padding: 10px 20px;
    background-color: white;
}

.popup-holder .login-popup header {
    padding: 20px;
    font-size: 20px;
    position: relative;
    border-bottom: 1px solid black;
}

.popup-holder .login-popup header:after {
    top: 20px;
    right: 5px;
    font-size: 40px;
    cursor: pointer;
    content: '\00d7';
    position: absolute;
}

.popup-holder .login-popup .login-characters {
    width: 100%;
}

@media (max-width: 500px) {
    .popup-holder .login-popup .login-characters {
        width: 300px;
    }
}

@media (max-height: 500px) {
    .popup-holder .login-popup .login-characters {
        width: 200px;
    }
}

.popup-holder .login-popup p {
    font-weight: 300;
}

.popup-holder .login-popup p span.spacer {
    padding: 0 15px;
}

@media (max-width: 420px) {
    .popup-holder .login-popup p span.spacer {
        padding: 0;
    }
}

#slideshow #holder {
    display: flex;
    position: static;
    transition: left 0.5s;
    justify-content: space-around;
}

@media (max-width: 680px) {
    #slideshow #holder {
        position: relative;
        justify-content: start;
    }
}

#slideshow #holder .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    z-index: 2;
    position: relative;
    text-align: center;
}

@media (max-width: 680px) {
    #slideshow #holder .col {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        padding: 0 10px;
    }
}

#slideshow #holder .col>div {
    height: 250px;
    padding-top: 30px;
}

#slideshow #holder .col p {
    color: white;
}

#slideshow .navs {
    z-index: 2;
    display: none;
    position: relative;
    text-align: center;
}

@media (max-width: 680px) {
    #slideshow .navs {
        display: block;
    }
}

#slideshow .navs span {
    width: 30px;
    height: 30px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 100%;
    display: inline-block;
    border: 1px solid #3B465C;
}

#slideshow .navs span.selected {
    background-color: #3B465C;
}

form label {
    display: block;
    padding: 20px 0;
    font-size: 22px;
}

form label input,
form label textarea {
    width: 100%;
    color: inherit;
    display: block;
    font-size: inherit;
    margin-top: 10px;
    line-height: 50px;
    border: 1px solid #3B465C;
    background-color: transparent;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

form label span {
    display: none;
    padding-top: 3px;
}

form label.error {
    color: red;
}

form label.error input {
    border: 1px solid red;
}

form label.error span {
    display: block;
}

form label ul {
    padding: 0;
    margin: 20px 0 0;
}

form label ul li {
    color: white;
    cursor: pointer;
    padding: 7px 40px;
    margin: 0 20px 10px 0;
    display: inline-block;
    background-color: #3B465C;
}

@media (max-width: 640px) {
    form label ul li {
        padding: 10px;
    }
}

form label ul li.selected {
    color: #3B465C;
    background-color: #FBB157;
}

form label.line {
    font-weight: 400;
}

form label.line input {
    width: 100px;
    margin-left: 20px;
    display: inline-block;
}

.carer-register,
.client-create,
.job-create {
    z-index: 2;
    font-weight: 300;
    position: relative;
    padding: 20px 10px 80px;
}

.carer-register fieldset,
.client-create fieldset,
.job-create fieldset {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 640px) {

    .carer-register fieldset,
    .client-create fieldset,
    .job-create fieldset {
        flex-direction: column;
    }
}

.carer-register fieldset label,
.client-create fieldset label,
.job-create fieldset label {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
}

.carer-register h2,
.client-create h2,
.job-create h2 {
    line-height: 35px;
}

.carer-register .agreement,
.client-create .agreement,
.job-create .agreement {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
}

.carer-register .agreement span,
.client-create .agreement span,
.job-create .agreement span {
    margin: 0;
    cursor: pointer;
}

.carer-register .agreement .checkbox,
.client-create .agreement .checkbox,
.job-create .agreement .checkbox {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;
    padding-left: 60px;
}

.carer-register .agreement .checkbox:before,
.client-create .agreement .checkbox:before,
.job-create .agreement .checkbox:before,
.carer-register .agreement .checkbox:after,
.client-create .agreement .checkbox:after,
.job-create .agreement .checkbox:after {
    content: '';
    position: absolute;
}

.carer-register .agreement .checkbox:before,
.client-create .agreement .checkbox:before,
.job-create .agreement .checkbox:before {
    left: 0;
    top: 2px;
    width: 40px;
    height: 40px;
    border: 1px solid #3B465C;
}

.carer-register .agreement .checkbox:after,
.client-create .agreement .checkbox:after,
.job-create .agreement .checkbox:after {
    left: 7px;
    opacity: 0;
    top: 9px;
    transform: scale(4);
    width: 28px;
    height: 28px;
    background-color: #3B465C;
    transition: opacity 0.2s, transform 0.2s;
}

.carer-register .agreement .checkbox.selected:after,
.client-create .agreement .checkbox.selected:after,
.job-create .agreement .checkbox.selected:after {
    opacity: 1;
    transform: scale(1);
}

.carer-register .agreement a,
.client-create .agreement a,
.job-create .agreement a {
    text-decoration: underline;
}

.carer-register .btn.white,
.client-create .btn.white,
.job-create .btn.white {
    color: #3B465C;
}

.carer-register input[type=submit],
.client-create input[type=submit],
.job-create input[type=submit] {
    padding: 0 60px;
}

.carer-register .buttons,
.client-create .buttons,
.job-create .buttons {
    padding-top: 40px;
}

.carer-register .buttons button,
.client-create .buttons button,
.job-create .buttons button {
    margin: 0 30px 30px 0;
}

.download-app {
    display: flex;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    align-items: center;
    justify-content: center;
}

.download-app:before {
    content: '';
    z-index: -1;
    top: 100px;
    left: 10px;
    right: 10px;
    bottom: 80px;
    position: absolute;
    border-radius: 50px;
    background-color: #F5F7FA;
}

@media (max-width: 1000px) {
    .download-app {
        padding-bottom: 100px;
        flex-direction: column;
    }
}

.download-app div {
    padding: 20px;
    text-align: center;
}

@media (max-width: 1000px) {
    .download-app div:first-child {
        padding-bottom: 0;
    }
}

@media (max-width: 480px) {
    .download-app div:first-child img {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .download-app div:last-child {
        padding-top: 0;
    }
}

.download-app div p {
    color: #3B465C;
    font-size: 36px;
    max-width: 520px;
    text-align: left;
    line-height: 50px;
}

.download-app div p img {
    float: left;
    width: 125px;
    padding-right: 25px;
}

@media (max-width: 560px) {
    .download-app div p {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }

    .download-app div p img {
        float: none;
        display: block;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;
    }
}

.download-app div a {
    padding: 5px;
    display: inline-block;
}

.download-app div a:hover {
    text-decoration: none;
}

.teams h1 {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    color: white;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    position: relative;
}

@media (max-width: 768px) {
    .teams h1 {
        margin-top: 80px;
    }
}

.teams h1 small {
    display: block;
    font-size: 0.6em;
    font-weight: 300;
    line-height: 30px;
    margin: 30px 0 40px;
}

.teams-cover {
    max-width: 1116px;
    margin-left: auto;
    margin-right: auto;
    height: 500px;
    padding: 0 20px;
    overflow: visible;
}

@media (max-width: 1000px) {
    .teams-cover {
        height: 400px;
    }
}

@media (max-width: 900px) {
    .teams-cover {
        height: 350px;
    }
}

@media (max-width: 800px) {
    .teams-cover {
        height: 300px;
    }
}

@media (max-width: 768px) {
    .teams-cover {
        height: auto;
        position: static;
    }
}

.teams-cover img {
    z-index: 2;
    top: -160px;
    width: 100%;
    display: block;
    position: relative;
}

@media (max-width: 768px) {
    .teams-cover img {
        position: static;
    }
}

.open-jobs h2 {
    font-weight: 400;
    text-align: center;
}

.open-jobs .jobs {
    color: white;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0 60px;
    justify-content: start;
}

.open-jobs .jobs div {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 31%;
    flex: 0 0 31%;
    margin: 10px;
    padding: 20px 30px 35px;
    background-color: #3B465C;
}

.open-jobs .jobs div p {
    font-weight: 300;
}

@media (max-width: 900px) {
    .open-jobs .jobs div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
    }
}

@media (max-width: 600px) {
    .open-jobs .jobs div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }
}

.teams-vid {
    padding: 60px 0;
    background-size: cover;
    /* background-image: url('../images/dark-circle-bg.svg'); */
}

@media (max-width: 600px) {
    .teams-vid {
        padding: 30px 0;
    }
}

.teams-vid .two-columns {
    max-width: 1000px;
    padding-bottom: 0;
    align-items: center;
}

.teams-vid .two-columns div:last-child {
    padding: 0;
    position: relative;
}

.teams-vid .two-columns div:last-child:after {
    top: 50%;
    left: 50%;
    content: '';
    height: 0;
    cursor: pointer;
    position: absolute;
    border-right: none;
    transform: translate(-50%, -50%);
    border-top: 40px solid transparent;
    border-left: 60px solid #3B465C;
    border-bottom: 40px solid transparent;
}

.teams-vid .two-columns div:last-child img {
    width: 100%;
}

.teams-vid p {
    color: white;
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
}

.profile {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.profile main app-root {
    width: 100%;
}

.profile * {
    box-sizing: border-box;
}

.profile .btn {
    border: none;
    color: white;
    cursor: pointer;
    padding: 20px 30px;
}

.profile .btn.red {
    background-color: #F15B5B;
}

.profile .btn.red:hover {
    background-color: #f47e7e;
}

.profile a.btn {
    display: block;
    padding: 20px 50px;
    text-align: center;
    text-decoration: none;
}

.profile .input {
    display: flex;
}

@media (max-width: 440px) {
    .profile .input {
        flex-direction: column;
    }
}

.profile .input input {
    font-size: 18px;
    min-width: 100px;
    font-weight: 300;
    padding: 20px 30px;
    letter-spacing: 0.8px;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
}

.profile .input input[type=text] {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    border: none;
    outline: none;
}

.profile .input input[type=button] {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    border: none;
    color: white;
    cursor: pointer;
    padding: 20px 30px;
    background-color: #F15B5B;
}

.profile .input input[type=button].red {
    background-color: #F15B5B;
}

.profile .input input[type=button].red:hover {
    background-color: #f47e7e;
}

.profile .input input[type=button]:hover {
    background-color: #f47e7e;
}

.profile .mobile {
    width: 100%;
    padding: 20px;
    display: none;
    cursor: pointer;
    position: absolute;
    text-align: center;
    z-index: 1;
}

.profile .mobile img {
    width: 60px;
}

@media (max-width: 1200px) {
    .profile .mobile {
        display: block;
    }
}

.profile .mobile #hamburger {
    top: 35px;
    left: 20px;
    width: 40px;
    height: 40px;
    display: block;
    cursor: pointer;
    position: absolute;
    font-size: 10px;
    font-weight: 500;
}

.profile .mobile p {
    position: absolute;
    top: -15px;
    left: 4px;
    font-size: 10px;
    font-weight: 600;
    margin: 0;
}

.profile .mobile #hamburger .line {
    left: 0;
    width: 100%;
    height: 4px;
    display: block;
    position: absolute;
    background-color: #3B465C;
}

.profile .mobile #hamburger>span {
    left: 0;
    width: 100%;
    height: 4px;
    display: block;
    position: absolute;
    background-color: #3B465C;
    top: 11.33333333px;
    overflow: visible;
}

.profile .mobile #hamburger>span:before,
.profile .mobile #hamburger>span:after {
    left: 0;
    width: 100%;
    height: 4px;
    display: block;
    position: absolute;
    background-color: #3B465C;
    content: '';
}

.profile .mobile #hamburger>span:before {
    top: -11.33333333px;
    animation: mymoveT 2.5s ease-in-out infinite;
    -webkit-animation: mymoveT 2.5s ease-in-out infinite;
}

.profile .mobile #hamburger>span:after {
    top: 11.33333333px;
    animation: mymoveB 2.5s ease-in-out infinite;
    -webkit-animation: mymoveB 2.5s ease-in-out infinite;
}

@keyframes mymoveT {
    0% {
        top: 0px;
    }

    10% {
        top: 0px;
    }

    30% {
        top: -10px;
    }

    70% {
        top: -10px;
    }

    90% {
        top: 0px;
    }

    100% {
        top: 0px;
    }
}

@keyframes mymoveB {
    0% {
        top: 0px;
    }

    10% {
        top: 0px;
    }

    30% {
        top: 10px;
    }

    70% {
        top: 10px;
    }

    90% {
        top: 0px;
    }

    100% {
        top: 0px;
    }
}

.profile main {
    display: flex;
}

@media (max-width: 1200px) {
    .profile main {
        display: block;
    }
}

.profile main>div:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    display: flex;
    /* min-width: 360px; */
    justify-content: end;
    /* background-color: #3B465C; */
}

.profile main .box-wrapper {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    width: 75%;
    margin-left: 25%;
    background-color: white;
}

.profile main #menu {
    width: 25%;
    display: flex;
    overflow: auto;
    max-height: 100vh;
    padding: 25px 40px;
    flex-direction: column;
    background-color: #3B465C;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
}

.profile main #menu #close {
    top: 20px;
    right: 20px;
    color: white;
    display: none;
    cursor: pointer;
    font-size: 42px;
    position: absolute;
}

@media (max-width: 1200px) {
    .profile main .box-wrapper {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
    }

    .profile main #menu {
        z-index: 2;
        left: -100%;
        height: 100vh;
        position: fixed;
        transition: left 0.5s;
        -webkit-transition: left 0.5s;
        -moz-transition: left 0.5s;
        -ms-transition: left 0.5s;
        -o-transition: left 0.5s;
    }

    .profile main #menu.toggled {
        left: 0px;
    }

    .profile main #menu #close {
        display: block;
    }
}

.profile main #menu img.logo {
    width: 150px;
    padding-bottom: 40px;
}

.profile main #menu a {
    color: white;
    display: block;
    padding: 10px 0 15px 0;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

.profile main #menu a:hover {
    text-decoration: underline;
}

.profile main #menu a.thin {
    font-size: 16px;
    font-weight: 600;
}

.profile main #menu a.profiler {
    padding-bottom: 30px;
}

.profile main #menu a.profiler * {
    vertical-align: middle;
}

.profile main #menu a.profiler span {
    padding-left: 30px;
    display: inline-block;
}

.profile main #menu a.profiler:hover {
    text-decoration: none;
}

.profile main #menu hr {
    margin: 20px 0;
    border-bottom: 1px solid white;
}

.profile main #content {
    max-width: 1200px;
    background-size: 900px;
    background-repeat: no-repeat;
    background-position: -260px -670px;
    /* background-image: url("/themes/curam/assets/images/huskies/client/orange-circle.svg"); */
    color: #3B465C;
    font-size: 19px;
    font-weight: 300;
    padding: 40px;
}

@media (max-width: 640px) {
    .profile main #content {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.profile main #content h1,
.profile main #content h2 {
    font-weight: 400;
}

.profile main #content h2 {
    margin-top: 0;
    padding-top: 0;
}

.profile main #content .tiles {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 980px) {
    .profile main #content .tiles {
        flex-direction: column-reverse;
    }

    .profile .input .filter-input {
        min-height: 61px;
        margin: 0;
    }

    .profile .input button.find-carer-js-block {
        margin: 0;
    }
}

.profile main #content .tiles.padded>div {
    padding: 40px;
}

@media (max-width: 500px) {
    .profile main #content .tiles.padded>div {
        padding-left: 20px;
        padding-right: 20px;
    }

    /* .profile main #menu.toggled {
    width: 300px;
    padding: 25px 20px;
  } */
}

.profile main #content .tiles>div:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 57%;
    flex: 0 0 57%;
}

.profile main #content .tiles>div:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 57% - 40px);
    flex: 0 0 calc(100% - 57% - 40px);
}

@media (max-width: 980px) {
    .profile main #content .tiles>div:last-child {
        margin-bottom: 40px;
    }
}

.profile main #content .tiles>div.characters {
    position: relative;
}

@media (max-width: 980px) {
    .profile main #content .tiles>div.characters {
        display: none;
    }
}

.profile main #content .tiles>div.characters img {
    right: 0;
    width: 170px;
    bottom: -60px;
    position: absolute;
}

.profile main #content .tiles>div.dark {
    color: white;
    background-color: #3B465C;
}

.profile main #content .tiles>div.orange {
    background-color: #FBB157;
}

.client_dashboard h1.name-title {
    padding-bottom: 30px;
}

/*# sourceMappingURL=curam.css.map */

/*------------------------------ Client Account View CSS Start -------------------------------------------*/
.menu-scroll {
    scrollbar-width: thin;
    scrollbar-color: #666b7a rgba(33, 37, 41, 0);
}

.menu-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.menu-scroll::-webkit-scrollbar-track {
    background: rgba(33, 37, 41, 0);
}

.menu-scroll::-webkit-scrollbar-thumb {
    background: #666b7a;
}

.menu-scroll::-webkit-scrollbar-track, .menu-scroll::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

img {
    max-width: 100%;
    height: auto;
}

.d_flex-CS {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.borB-0 {
    border-bottom: 0px !important;
}

.text-bold {
    font-weight: 700 !important;
}

/*------------ padiing ----------------*/
.padT-20 {
    padding-top: 20px !important;
}

.padT-30 {
    padding-top: 30px !important;
}

.padB-15 {
    padding-bottom: 15px !important;
}

.padB-10 {
    padding-bottom: 10px !important;
}

.padB-0 {
    padding-bottom: 0px !important;
}

.padR-10 {
    padding-right: 10px !important;
}

.marB-10 {
    margin-bottom: 10px !important;
}

.marB-20 {
    margin-bottom: 20px !important;
}

.marB-30 {
    margin-bottom: 30px !important;
}

.marB-40 {
    margin-bottom: 40px !important;
}

.marT-0 {
    margin-top: 0px !important;
}


.text_center {
    text-align: center !important;
}

.d-flex-cst {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/*----------------------------*/

.custom-flex-cc {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-img {
    width: 48px;
    border-radius: 30px;
}

/* .right-content {
  width: 100%;
  background-size: 900px;
  background-repeat: no-repeat;
  background-position: right -300px top -630px;
  background-image: url("../images/client-view/orange-circle.svg");
} */
.right-content {
    width: 100%;
    margin-left: 0;
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: right -200px top -470px;
    background-image: url("../src/assets/images/client-view/orange-circle.svg");
}

.profile main #content h1 {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
}

.client-head-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.client-head-top .client-view-block-head-btn {
    padding: 12px 12px 12px 15px !important;
}

.client-head-top .applicants-post {
    color: rgba(54, 70, 91, 1);
    font-size: 14px;
    font-weight: 400;
    padding-top: 0px;
    padding-right: 20px;
}

.client-head-top-rt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.profile .btn.client-head-btn {
    background-color: #F15B5B;
    padding: 12px 20px;
    font-size: 16px;
}

.client-view-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.client-view-container {
    max-width: 990px;
    padding-top: 60px;
    width: 100%;
}

.client-view-block {
    background-color: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    padding: 20px 20px 20px 35px;
    width: 100%;
    margin-bottom: 25px;
}

a.applicants-link {
    text-decoration: none;
}

.client-view-block-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.client-view-block-head-lt {
    font-size: 22px;
    color: #EE743B;
    font-weight: 500;
    width: calc(100% - 170px);
}

.client-view-block-head-btn {
    background-color: #F15B5B;
    width: 150px;
    padding: 7px 7px 7px 15px !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.client-view-block-head-btn .applicants {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
}

.client-view-block-head-btn .applicants-num {
    background-color: #fff;
    width: 25px;
    height: 25px;
    font-size: 14px;
    color: #F15B5B;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;

}

.applicants-location, .applicants-locationW {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
}

.applicants-location img, .applicants-locationW img {
    padding-right: 7px;
}

.applicants-location {
    color: #36465B;
}

.applicants-locationW {
    color: #fff;
    margin-bottom: 30px;
}


.client-view-block p {
    color: #36465B;
    font-size: 15px;
    font-weight: 400;
    margin: 15px 0 20px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 45px;
    overflow: hidden;
    line-height: 1.4;
}

.client-view-block-footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.client-view-block-footer-lt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 370px);
    flex-wrap: wrap;
}

.client-view-block-footer-rt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 370px;
}

.applicants-status {
    font-size: 12px;
    font-weight: 400;
    padding: 3px 16px;
    white-space: nowrap;
    margin-right: .25rem;
    margin-bottom: .25rem;
}

.applicants-status.status-green {
    border: #049e98 1px solid;
    color: #049e98;
}

.applicants-status.status-orange {
    border: #EE743B 1px solid;
    color: #EE743B;
}

.applicants-status.status-blue {
    border: #3f76ba 1px solid;
    color: #3f76ba;
}

.applicants-status.status-purple {
    border: #832b5f 1px solid;
    color: #832b5f;
}

.applicants-post {
    color: rgba(54, 70, 91, .5);
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
}


a.applicants-close-edit-btn {
    color: #EE743B;
    font-size: 15px;
    padding-right: 25px;
    text-decoration: none;
    font-weight: 400;
}

.approval-published, .approval-pending, .approval-closed {
    width: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-decoration: none;
    font-weight: 400;
    height: 30px;
}

.approval-published {
    background-color: #DDFDF0;
    border: 1px solid #049E98;
    color: #0D7975;
}

.approval-pending {
    background-color: #FFE3E3;
    border: 1px solid #FF1212;
    color: #F72424;
}

.approval-closed {
    background-color: #EDEDED;
    border: 1px solid #8B8B8B;
    color: #767676;
}

.top-back a {
    font-size: 18px;
    color: #000000;
    text-decoration: none;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.top-back a img {
    padding-right: 5px;
}

/*---------------------------------------*/
.job-detail-block-top {
    background-color: #3C527E;
    padding: 20px 30px 30px 30px;
    margin-bottom: 35px;
}

.job-detail-block-top h2 {
    font-size: 26px;
    color: #fff;
    padding-bottom: 15px;
    margin: 0;
}

.btn-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3px;
}

.whiteB-btn-sm {
    border: 1px solid #fff !important;
    padding: 7px 20px !important;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    margin-right: 12px;
    margin-bottom: 10px;
}

.btn-block-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.whiteB-btn-lg {
    border: 1px solid #fff !important;
    padding: 8px 20px !important;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    margin-right: 8px;
}

.job-block-btn {
    background-color: #F15B5B;
    padding: 9px 20px !important;
    font-size: 14px;
    font-weight: 400;
}

.job-block-btn-rt {
    background-color: #F15B5B;
    padding: 9px 20px !important;
    font-size: 14px;
    font-weight: 400;
}



.job-detail-block {
    background-color: #3B465C;
    padding: 20px 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
}

.job-detail-block-lt {
    width: 150px;
}

.job-detail-block-lt img {
    max-width: 150px;
    object-fit: cover;
    width: 100%;
    min-height: 190px;
    max-height: 190px;
}

.job-detail-block-rt {
    width: calc(100% - 150px);
    min-height: 190px;
    /* position: relative; */
    color: #fff;
    padding-left: 30px;
}

.job-block-rt-top {
    min-height: 150px;
}

.fav-carer .job-block-rt-top {
    min-height: 125px;
}

.add-like {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.add-like .img-wh {
    min-width: 23px;
    height: auto;
}


.job-block-rt-top p {
    font-size: 16px;
    color: #fff;
    line-height: 1.3;
    margin: 0 0 33px 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 42px;
    overflow: hidden;
}

.like-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.job-block-info {
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
    padding-bottom: 10px;
}

.job-block-info-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-bottom: 15px;
}

.job-block-info-content img {
    padding-right: 5px;
}

.job-block-info-line {
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    display: inline-block;
    margin: 0;
    border-right: solid 1px rgba(255, 255, 255, 0.25);
    padding-right: 10px;
    margin-left: 10px;
}

.job-block-info-line:last-child {
    border-right: 0px;
}

.job-block-info-line:first-child {
    margin-left: 0px;
}

.job-block-rt-bottom {
    /* position: absolute;
  bottom: 0px;
  left: 30px; */
    width: 100%;
    /* padding-right: 30px; */
}

.job-block-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.whiteB-sm {
    border: 1px solid #fff !important;
    padding: 7px 20px !important;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    margin: 5px 10px 5px 0px;
    display: inline-block;
}

.job-block-bottom-rt, .person-detail-footer, .account-detail-footer, .fav-job-block-bottom-rt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.job-block-bottom-rt {
    width: 290px;
}

.fav-job-block-bottom-rt {
    width: 150px;
}

.job-block-bottom-lt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 290px);
    flex-wrap: wrap;
}

.fav-block-bottom-lt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 150px);
    flex-wrap: wrap;
}

.job-block-covid .covid-img {
    max-width: 60px;
    height: auto;
    padding: 5px 0;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.job-block-covid-bt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.covid-img {
    max-width: 60px;
    height: auto;
    padding: 5px 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.job-block-covid-bt .like-img {
    width: auto;
    padding-bottom: 0px;
}

.person-detail-wrapper {
    border-top: 1px solid #E2E2E2;
    padding-top: 35px;
    margin-top: 30px !important;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 35px;
    margin-bottom: 20px !important;
}

.person-detail-wrapper h2 {
    font-size: 20px !important;
    color: #36465B;
    font-weight: 400 !important;
    margin: 0 0 30px 0 !important;
}

.person-detail-wrapper p {
    font-size: 16px !important;
    color: #36465B;
    line-height: 1.4;
    font-weight: normal !important;
    margin: 0 !important;
}

.person-detail-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.person-detail-col {
    flex: 0 0 49%;
    width: 49%;
}

.person-detail-block {
    background-color: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    padding: 25px;
    margin-bottom: 20px;
    width: 100%;
}

.person-detail-block-bt {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.person-detail-block-bt .bt-col {
    flex: 0 0 40%;
    width: 40%;
}

.person-detail-block h2 {
    font-size: 20px !important;
    color: #36465B;
    font-weight: normal !important;
    margin: 0 0 20px 0 !important;
}

.person-detail-block .text-sm {
    font-size: 12px;
    color: #36465B;
    padding-bottom: 5px;
    font-weight: 400;
}

.person-detail-block .text-md {
    font-size: 16px;
    color: #36465B;
    font-weight: 400;
}

.person-detail-block .text-md-14 {
    font-size: 14px;
    color: #36465B;
    min-width: 25%;
    padding-bottom: 15px;
    font-weight: 400;
}

.person-detail-support {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.blackB-btn-lg {
    border: 1px solid #3B465C !important;
    padding: 13px 20px !important;
    color: #3B465C !important;
    text-decoration: none;
    font-size: 16px;
    margin-right: 8px;
}

.person-detail-btn {
    background-color: #F15B5B;
    padding: 14px 20px !important;
    font-size: 16px;
}

/*------------------- custom table ---------------*/
.curam-job-table {
    display: block;
}

.curam-job-table table {
    border: 0px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    font-weight: 300 !important;
    color: #36465B !important;
}

.curam-job-table table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

.curam-job-table table tr {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    padding: .35em;
}

.curam-job-table table th,
.curam-job-table table td {
    padding: .625em .625em .625em 0;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
}

.curam-job-table table th {
    font-size: 13px;
    font-weight: 400;
}


/*------- find a carer -------*/
.find-carer-container {
    max-width: 1080px;
    padding-top: 60px;
    width: 100%;
}

.filters_input-carer {
    margin-top: 30px;
    background-color: #ffffff;
}

.filters_input-carer .field {
    max-width: 800px;
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.filters_input-carer input {
    line-height: 50px !important;
    height: 50px !important;
    min-width: 100px;
    flex: 1 1 auto;
    border: solid 1px #3b465c;
    font-size: 16px;
    color: #3b465c;
    padding-left: 15px;
    border-right: 0px;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none !important;
    outline: none !important;
    margin-right: -2px;
}

.filters_input-carer input:focus {
    outline: none;
}

.filters_input-carer input::placeholder {
    font-size: 16px;
    color: #3b465c;
}

.filters_input-carer .btn.find-carer-btn {
    background-color: #f15b5b;
    color: #ffffff;
    flex: 1 1 200px;
    max-width: 200px;
    display: inline-block;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 0 !important;
    font-weight: 600;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none !important;
    outline: none !important;
}

.filter-type-carer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 800px;
}

.filter-type-carer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.filter-type-carer ul li {
    flex: 0 0 24%;
}

.filter-type-carer input[type=radio] {
    display: none;
}

.filter-type-carer input[type=radio]+label {
    min-width: 140px;
    font-size: 19px;
    line-height: 34px;
    color: #ffffff;
    margin: 0px 20px 15px 0px;
    width: auto;
    background-color: #3b465c;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}

.filter-type-carer input[type=radio]:checked+label {
    background-color: #fbb157;
}

.find-carer-window {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
}

.find-carer-window-lt {
    flex: 0 0 32%;
    width: 32%;
}

.find-carer-window-rt {
    flex: 1 1 auto;
    margin-left: 60px;
}

.lt-filters_box {
    padding: 40px 25px;
    display: block;
    background-color: #3b465c;
}

.rt-results-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.list-profile {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #3b465c;
    margin-bottom: 20px;
}

.list-profile a {
    text-decoration: none;
}

.list-profile-lt {
    max-width: 205px;
    padding: 20px;
}

.list-profile-lt .profile-photo {
    max-width: 165px;
    height: auto;
    object-fit: cover;
    width: 100%;
}

.list-profile-lt .profile-badge-img {
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

.profile-badge-img img {
    max-width: 90px;
    height: auto;
}

.list-profile-rt {
    width: 100%
}

.list-profile-rt-block {
    position: relative;
    padding: 20px 110px 20px 0;
}

.list-profile-info-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

/* .info-lt{
  width: calc(100% - 100px);
}
.info-rt{
  max-width: 100px;
} */
.list-profile-rt h4 {
    font-size: 30px;
    color: #fff;
    line-height: 1;
    margin: 0;
    text-transform: uppercase;
}

.last-active {
    font-size: 16px;
    color: #fff;
    margin-top: 12px;
}

.list-profile-info-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-bottom: 12px;
    padding-top: 12px;
}

.list-profile-info-line {
    font-size: 16px;
    line-height: 15px;
    color: #ffffff;
    display: inline-block;
    margin: 0;
    border-right: solid 1px rgba(255, 255, 255, 1);
    padding-right: 10px;
    margin-left: 10px;
}

.list-profile-info-line:last-child {
    border-right: 0px;
}

.list-profile-info-line:first-child {
    margin-left: 0px;
}

.list-profile-rt p {
    font-size: 16px;
    color: #fff;
    line-height: 1.4;
    margin: 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    height: 133px;
    overflow: hidden;
    margin-bottom: 30px;
}

.list-profile-care-type {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
}

.list-profile-care-type ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background-color: #353f54;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 105px;
}

.list-profile-care-type ul li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 15px;
    line-height: 21px;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 5px 10px 5px 0px;
    border-left: 0;
    max-width: 100px;
}

.list-profile-care-type ul li svg {
    display: inline-block;
    width: 45px;
    height: 30px;
    margin: 5px 0px;
}

.list-profile-care-type .available-head {
    display: none;
}

.list-profile-options {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 30px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.list-profile-options li {
    display: inline-block;
    border: solid 1px #ffffff;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    padding: 8px;
    margin-right: 10px;
    margin-top: 10px;
}

.more-exp-secion {
    display: none;
}

li.more-exp {
    background: #fff;
    color: #000000;
}

.list-profile-bt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 0px;
}

.list-profile-bt .view_profile {
    background-color: #f15b5b;
    color: #ffffff;
    text-decoration: none;
    padding: 0px 15px !important;
    font-weight: 700;
    line-height: 32px;
}



/*##############################*/
.filters_categories {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.filters_categories>li {
    padding: 7px 0;
}

.filters_categories>li:first-child {
    padding-top: 0;
}

@media (min-width: 768px) {
    .filters_categories>li {
        padding: 14px 0;
    }
}

.filters_categories>li ul {
    display: none;
}

.filters_categories>li.active .filter-toggle svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.filter-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    font-weight: 300;
    cursor: pointer;
}

.filter-toggle:hover {
    text-decoration: none;
}

@media (min-width: 768px) {
    .filter-toggle {
        font-size: 20px;
        line-height: 27px;
        color: #ffffff;
    }
}

.filter-toggle svg {
    width: 15px;
    height: 15px;
    color: #ffffff;
    margin-left: 15px;
}


.filter-by-text {
    display: none;
    position: relative;
}

li.active .filter-by-text {
    display: block;
}

li.active .filter-by-text input {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding-right: 50px;
    font-size: 19px;
    line-height: 42px;
    color: #3b465c;
    padding-left: 15px;
}


@media (min-width: 768px) {
    li.active .filter-by-text input {
        font-size: 19px;
        line-height: 50px;
        color: #3b465c;
        outline: none;
    }
}

.filter-by-text:after {
    content: '';
    display: block;
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
    background-image: url("../src/assets/images/client-view/search.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media (min-width: 768px) {
    .filter-by-text:after {
        width: 24px;
        height: 24px;
    }
}

.filtered-out {
    display: none;
}

.no-results {
    display: none;
}

.list-empty .no-results {
    display: inline-block;
    text-align: center;
    width: 100%;
}


.get-started-carer {
    display: none;
}

@media (min-width: 768px) {
    .get-started-carer {
        display: block;
        background-color: #fbb157;
        padding: 20px;
        margin-top: 30px;
    }
}

.get-started-carer h3 {
    font-size: 21px;
    line-height: 27px;
    font-weight: 300;
    color: #3b465c;
    margin: 0;
    letter-spacing: -0.5px;
}

.get-started-carer p {
    font-size: 18px;
    line-height: 21px;
    color: #3b465c;
    margin-bottom: 30px;
}

.get-started-carer .btn, .get-started-carer .filters_box-toggle {
    line-height: 42px;
    padding: 2px 10px !important;
    background-color: #F15B5B;
}

.how-curam {
    display: block;
    background-color: #fbb157;
    padding: 30px;
    margin-bottom: 20px;
}

.how-curam h3 {
    font-size: 23px;
    line-height: 27px;
    font-weight: 300;
    color: #3b465c;
    margin: 0;
    letter-spacing: -0.5px;
    padding-bottom: 15px;
}

.how-curam p {
    font-size: 18px;
    line-height: 21px;
    color: #3b465c;
    margin: 0;
}

/*---------*/
.filterbox-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px;
}

.filterbox-buttons .btn, .filterbox-buttons .filters_box-toggle {
    border: solid 1px #ffffff;
    font-size: 14px;
    line-height: 35px;
    color: #3b465c;
    padding: 0 10px !important;
    font-weight: 700;
}

@media (min-width: 1024px) {
    .filterbox-buttons .btn, .filterbox-buttons .filters_box-toggle {
        font-size: 16px;
        line-height: 35px;
        color: #3b465c;
    }
}

.filters_box-toggle {
    display: inline-block;
    background-color: #ffffff;
    text-align: center;
    font-size: 16px;
    line-height: 50px;
    color: #3b465c;
    font-weight: 600;
    cursor: pointer;
    padding: 0 20px;
    white-space: nowrap;
}

.btn:hover, .filters_box-toggle:hover {
    text-decoration: none;
}

.filters_box-toggle {
    text-align: center;
    width: 100%;
    border: solid 1px #3b465c;
    line-height: 38px;
    margin: 20px 0;
}

@media (min-width: 768px) {
    .filters_box-toggle {
        display: none;
    }
}

.filterbox-buttons .filter-apply {
    background-color: #fff;
    color: #3b465c;
    font-weight: 600 !important;
}

.filterbox-buttons .filter-clear {
    background-color: #3b465c;
    color: #ffffff;
    font-weight: 600 !important;
}

.lt-filters_box {
    background-color: #3b465c;
    padding: 30px 20px;
    display: none;
}

.lt-filters_box.active {
    display: block;
}

@media (min-width: 768px) {
    .lt-filters_box {
        padding: 40px 25px;
        display: block;
    }
}

.filters_options {
    display: none;
    background-color: #ffffff;
    padding: 12px;
    list-style-type: none;
    margin: 0;
    margin-top: 26px;
    max-height: 182px;
    overflow: auto;
}

@media (min-width: 768px) {
    .filters_options {
        padding: 16px;
        max-height: 188px;
    }
}

.filters_options>li {
    padding: 6px 0;
}

li.active .filters_options {
    display: block;
}

.filters_options label {
    cursor: pointer;
}

.filters_options input[type=checkbox],
.filters_options input[type=radio] {
    display: none;
}

.filters_options input[type=checkbox]+label {
    position: relative;
    padding: 0px 55px 0px 0px;
    font-size: 16px;
    line-height: 21px;
    color: #3b465c;
    font-weight: 300;
    cursor: pointer;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.filters_options input[type=checkbox]+label:before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    border: solid 1px #3b465c;
}

.filters_options input[type=checkbox]+label .check {
    position: absolute;
    top: calc(50% + 3px);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
    display: none;
}

.filters_options input[type=checkbox]+label .check svg {
    width: 20px;
    height: 20px;
}

.filters_options input[type=checkbox]:checked+label .check {
    display: block;
}

.filters_options input[type=radio]+label {
    position: relative;
    padding: 0 55px 0 0;
    font-size: 16px;
    line-height: 21px;
    color: #3b465c;
    font-weight: 300;
    cursor: pointer;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.filters_options input[type=radio]+label:before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    border: solid 1px #3b465c;
    border-radius: 18px;
}

.filters_options input[type=radio]+label .check {
    position: absolute;
    top: 3px;
    right: 3px;
    display: none;
    width: 14px;
    height: 14px;
    background-color: #3b465c;
    border-radius: 14px;
}

.filters_options input[type=radio]:checked+label .check {
    display: block;
}

.svg-sprite {
    display: none;
}

/*############ shift payment ##################*/
.shift-payment-main {
    background: #FDF1EB;
}

.shift-payment-main .payment-wrapper {
    padding: 25px 0px;
}

.payment-wrapper .payment-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}

.payment-block-lt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.payment-block-rt {
    text-align: right;
}

.payment-block-rt h4 {
    font-size: 18px;
    color: #36465B;
    padding-bottom: 10px;
    margin: 0 !important;
    font-weight: 300;
}

.payment-block-rt h2 {
    font-size: 40px;
    color: #F15B5B;
    margin: 0 !important;
}

.payement-user {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.payment-block-lt h4 {
    font-size: 18px;
    color: #36465B;
    padding-bottom: 5px;
    margin: 0 !important;
    font-weight: 300;
    padding-left: 15px;
}

.payment-block-lt h4.text-red {
    color: #F15B5B;
    padding-top: 5px;
}

.payment-wrapper .payment-block-bt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 0 30px;
}

.payment-block-bt p {
    font-size: 14px;
    color: #36465B;
    margin: 15px 0;
}

.payment-wrapper .make-payement-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px 0;
}

.make-payement-block .make-payement-btn {
    display: initial !important;
}

.make-payement-btn.disabled {
    background-color: #f47e7e !important;
}

.security-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 30px;
}

.security-block-lt {
    background-color: #fff;
    min-width: 54px;
    min-height: 54px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.security-block p {
    margin: 0;
    font-size: 17px;
    line-height: 1.4;
}

.supported-cards-block h4 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
}

.supported-cards-block .all-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.supported-cards-block .cards {
    margin-right: 5px;
    margin-bottom: 10px;
}

.add-account {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 10px 20px 30px 20px;
}

.add-account a.btn {
    padding: 15px 20px;
}


/*#####################################################*/

/* ------------------ */
/* NEW CSS 26-11-2021 */
/* ------------------ */

.img-fluid {
    max-width: 100%;
}

/* Message Page */
.message-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 80px;
    padding-bottom: 50px;
    max-width: 1080px;
    /* max-width: 990px; */
    margin-left: auto;
}

.message-aside {
    max-width: 360px;
    flex: 0 0 360px;
    padding-right: 25px;
    padding-left: 25px;
    border-right: 1px solid #EDEDED;
}

.message-title {
    color: #36465B;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 24px;
}

.carer-search {
    font-size: 16px;
    color: #36465B;
    height: 50px;
    margin: 0;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.message-carer-list {
    margin-top: 15px;
    display: block;
    max-height: 750px;
    overflow: auto;
    padding-right: 15px;
    margin-right: -28px;
}

.message-carer-list::-webkit-scrollbar {
    width: 6px;
}

.message-carer-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D3D3D3;
}

.carer-list-single {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
}

.carer-list-single:hover {
    background-color: #f5f5f5;
}

.carer-list-single.active {
    background-color: #F7941D;
}

.carer-img {
    max-width: 52px;
    flex: 0 0 52px;
    height: 52px;
    border-radius: 100px;
    margin-right: 25px;
    overflow: hidden;
}

.carer-img img {
    max-width: 100%;
}

.carer-name {
    flex: 1;
}

.carer-name h4 {
    font-size: 20px;
    color: #EE743B;
    margin-bottom: 5px;
    margin-top: 0;
}

.carer-name p {
    font-size: 14px;
    color: #B5B5B5;
    margin: 0;
}

.carer-like {
    margin-right: auto;
}

.carer-like .like-link-block, .carer-like .like-link-block img {
    display: block;
}

.msg-count {
    max-width: 40px;
    flex: 0 0 40px;
}

.msg-count span {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    background-color: #EE743B;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carer-list-single.active .carer-name h4 {
    color: #ffffff;
}

.carer-list-single.active .carer-name p {
    color: rgba(255, 255, 255, 0.7);
}

.message-aside .btn.btn-red {
    background-color: #F15B5B;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 400;
}

.message-chat-content {
    /* max-width: calc(100% - 360px); */
    min-width: 10%;
    flex: 0 0 calc(100% - 360px);
    padding-right: 25px;
    padding-left: 25px;
}

.message-chat-heading {
    border-bottom: 1px solid #EDEDED;
}

.message-chat-heading .carer-list-single:hover {
    background-color: transparent;
    cursor: default;
}

.message-chat-heading .carer-list-single .carer-like {
    display: flex;
    align-items: center;
    gap: 15px;
}


.message-chat-main {
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    height: 700px;
    overflow: auto;
}

.msg-sent {
    margin-left: auto;
    margin-bottom: 20px;
}

.msg-sent-info {
    background-color: #36465B;
    padding: 10px 25px;
    font-size: 16px;
    color: #FFFFFF;
    max-width: 400px;
    word-break: break-all;
}

.msg-sent span {
    padding-right: 0px;
    font-size: 14px;
    color: #B5B5B5;
    margin-left: auto;
    display: table;
    margin-top: 3px;
}

.msg-reply {
    margin-right: auto;
    margin-bottom: 20px;
}

.msg-reply-info {
    background-color: #FFD9C7;
    padding: 10px 25px;
    font-size: 16px;
    color: #000000;
    max-width: 400px;
    word-break: break-all;
}

.msg-reply span {
    padding-left: 0px;
    font-size: 14px;
    color: #B5B5B5;
}

.message-chat-textbox {
    margin-top: auto;
    padding: 30px 35px;
    background-color: #FDF1EB;
    display: flex;
    justify-content: space-between;
}

.message-chat-textbox .input-msg {
    width: calc(100% - 100px);
    background-color: #ffffff;
    border-color: #EE743B;
    resize: none;
    padding-top: 6px;
    padding-left: 10px;
    font-size: 16px;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

.message-chat-textbox textarea {
    height: 35px;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none !important;
    outline: none !important;
}

.btn.message-sent-btn {
    background-color: #F15B5B;
    padding: 8px 20px !important;
    font-size: 16px !important;
    border: none !important;
    color: white;
    cursor: pointer;
    height: 35px !important;
    max-height: 35px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none !important;
    outline: none !important;
}

/* Invoice Wrapper Page */
.invoice-wrapper {
    padding-top: 80px;
    padding-bottom: 50px;
    max-width: 1080px;
    margin-left: auto;
}

.invoice-search {
    display: flex;
    padding-bottom: 30px;
    border-bottom: 1px solid #EDEDED;
    margin-bottom: 20px;
}

.input-box,
.select-box {
    font-size: 14px;
    color: #36465B;
    height: 50px;
    margin: 0;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 0;
}

.invoice-search select,
.invoice-search input {
    margin-right: 20px;
    max-width: 240px;
}

.invoice-search .btn {
    background-color: #F15B5B;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 400;
}

.invoice-content-single-box,
.invoice-content-heading {
    display: flex;
    align-self: center;
}

.label-head {
    display: none;
    flex: 0 0 100%;
    max-width: 100%;
    font-size: 16px;
    color: #86909C;
    font-weight: 500;
    margin-bottom: 5px;
}

.invoice-content-heading label {
    font-size: 16px;
    color: #86909C;
    margin-bottom: 25px;
}

.carer-info {
    padding-left: 25px !important;
}

.amount-info {
    padding-right: 25px !important;
}

.carer-info,
.status-info,
.period-info,
.amount-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.carer-info,
.invoice-content-heading label:first-child {
    max-width: 23%;
    flex: 0 0 23%;
}

.status-info,
.invoice-content-heading label:nth-child(2) {
    max-width: 20%;
    flex: 0 0 20%;
}

.period-info,
.invoice-content-heading label:nth-child(3) {
    max-width: 22%;
    flex: 0 0 22%;
}

.amount-info,
.invoice-content-heading label:last-child {
    max-width: 35%;
    flex: 0 0 35%;
}

.invoice-content-single-box {
    background-color: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
    padding: 15px 0;
    width: 100%;
    margin-bottom: 25px;
}

.invoice-content-single-box.pending {
    border: 1px solid #FBB157;
}

.invoice-content-single-box .carer-list-single,
.invoice-content-single-box .carer-list-single:hover {
    padding: 0;
    margin: 0;
    background-color: transparent;
    cursor: default;
}

.carer-list-single .covid-img, .amount-info .covid-img {
    max-width: 60px;
    height: auto;
    padding: 5px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #36465B;
}

.invoice-content-single-box .applicants-post {
    padding: 0;
}

.amount-info {
    justify-content: space-between;
    /* justify-content: space-evenly; */
}

.price-info {
    font-size: 16px;
    color: #36465B;
    display: flex;
    align-items: center;
    width: 110px;
}

.review-btn {
    width: 65px;
    margin-right: 20px;
}

.price-info img {
    margin-left: 15px;
    max-width: 25px;
}

.amount-info .btn {
    background-color: #F15B5B;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 400;
}

.amount-info .view-link {
    margin-right: 15px;
    font-size: 15px;
    color: #E75757;
    text-decoration: none;
}

.review-head-top-rt {
    justify-content: center;
}

.review-head-top-rt .applicants-post {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.review-head-top-rt img {
    max-width: 35px;
    margin-left: 15px;
}

.invoice-review-wrapper {
    padding-top: 80px;
    padding-bottom: 50px;
    max-width: 990px;
    margin-left: auto;
}

.invoice-review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review-carer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.review-carer-img {
    max-width: 75px;
    flex: 0 0 75px;
    height: 75px;
    border-radius: 100px;
    margin-right: 15px;
    overflow: hidden;
}

.review-carer-img img {
    max-width: 100%;
}

.review-carer-name {
    flex: 1;
}

.review-carer-name h4 {
    font-size: 20px;
    color: #F15B5B;
    margin-bottom: 5px;
    margin-top: 0;
    font-weight: 400;
}

.review-carer-name p {
    font-size: 14px;
    color: #B5B5B5;
    margin: 0;
}

.carer-btn .btn {
    background-color: #F15B5B;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 400;
}

.invoice-review-heading h3 {
    font-size: 20px;
    font-weight: 400;
    color: #687484;
    margin: 30px 0;
}

.invoice-review-heading-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.invoice-review-heading-flex h3 {
    font-size: 20px;
    font-weight: 700;
    color: #687484;
    margin: 30px 0;
}

.invoice-review-heading-flex-rt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 400;
}

.invoice-review-heading-flex-rt img {
    margin-left: 15px;
}

.invoice-review-box {
    background-color: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
    padding: 50px;
    width: 100%;
    margin-bottom: 25px;
    color: #36465B;
}

.invoice-review-box-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.invoice-review-box-heading h4 {
    font-size: 20px;
    color: #36465B;
    font-weight: 400;
    margin: 0;
}

.invoice-review-top-info {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
}

.to-invoice,
.from-invoice {
    max-width: 35%;
    flex: 0 0 35%;
}

.from-invoice label,
.from-invoice p,
.to-invoice label,
.to-invoice p {
    font-size: 20px;
    color: #687484;
    margin-top: 5px;
    font-weight: 700;
}

.num-invoice {
    max-width: 30%;
    flex: 0 0 30%;
}

.num-invoice span {
    display: block;
    font-size: 14px;
    color: #36465B;
    margin-bottom: 10px;
}

.invoice-address {
    max-width: 100%;
    flex: 0 0 100%;
}

.invoice-address span {
    display: block;
    font-size: 14px;
    color: #36465B;
}

.invoice-review-main-info {
    margin-top: 45px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(50, 70, 91, 0.2);
    margin-bottom: 45px;
}

.invoice-review-main-info h4 {
    font-size: 24px;
    color: #687484;
    font-weight: 600;
    margin-bottom: 5px;
}

.invoice-review-main-info p {
    font-size: 20px;
    color: #687484;
    margin-top: 0;
    margin-bottom: 5px;
}

.invoice-shift-detail-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.invoice-shift-detail-header h4 {
    font-size: 20px;
    color: #687484;
    font-weight: 400;
    margin-bottom: 5px;
}

.invoice-shift-detail-table {
    border-bottom: 1px solid rgba(50, 70, 91, 0.2);
    padding-bottom: 35px;
    margin-top: 35px;
}

.invoice-shift-detail-table table {
    width: 100%;
    border-collapse: collapse;
}

.invoice-shift-detail-table th {
    font-size: 14px;
    color: #36465B;
    font-weight: 400;
    padding: 5px 0;
    text-align: left;
}

.invoice-shift-detail-table table tbody tr {
    border-bottom: 1px solid #ccc;
}

.invoice-shift-detail-table table tbody tr:last-child {
    border-bottom: 0px solid #ccc;
}

.invoice-shift-detail-table td {
    font-size: 20px;
    color: #687484;
    font-weight: 700;
    padding: 15px 0;
    text-align: left;
}

.invoice-shift-detail-table th:last-child,
.invoice-shift-detail-table td:last-child {
    text-align: right;
}

.invoice-total {
    margin-top: 20px;
    margin-bottom: 25px;
    text-align: right;
}

.invoice-total label {
    font-size: 14px;
    color: #36465B;
}

.invoice-total h3 {
    font-size: 26px;
    color: #F15B5B;
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: 400;
}

.invoice-total.bb-1 {
    border-bottom: 1px solid rgba(50, 70, 91, 0.2);
    padding-bottom: 25px;
    padding-top: 10px;
}

.invoice-terms-condition {
    margin-top: 25px;
    padding-top: 30px;
    border-top: 1px solid rgba(50, 70, 91, 0.2);
}

.invoice-terms-condition p {
    font-size: 14px;
    color: #36465B;
    line-height: 1.7;
}

.invoice-terms-condition p a {
    color: #006EFF;
    text-decoration: none;
}

.approve-reject-btn {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.approve-reject-btn .btn-reject {
    background-color: #ffffff;
    color: #3B465C;
    border: 1px solid #3B465C;
    padding: 12px 20px !important;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
}

.approve-reject-btn .btn-approve {
    background-color: #F15B5B;
    padding: 12px 20px !important;
    font-size: 16px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    text-decoration: none;
    margin-left: auto;
}

.invoice-msg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 35px 45px;
    background-color: #FDF1EB;
    color: #36465B;
    font-size: 20px;
    margin-bottom: 10px;
}

.invoice-msg-refund {
    margin-bottom: 40px;
}

.invoice-msg img {
    margin-right: 20px;
    width: 44px;
    height: 44px;
}

.invoice-msg-price {
    color: #FD6D6D;
}

.service-agreement-review .invoice-review-box-heading {
    justify-content: flex-start;
}

.service-agreement-review .invoice-review-box-heading h4 {
    font-size: 28px;
}

.service-agreement-review p {
    font-size: 16px;
    color: #36465B;
    line-height: 1.6;
}

.approve-reject-rt {
    display: flex;
    margin-left: auto;
}

.approve-reject-rt .btn:not(:first-child) {
    margin-left: 20px;
}

.my-profile-wrapper {
    padding-top: 80px;
    padding-bottom: 50px;
    max-width: 800px;
    margin-left: auto;
}

.my-profile-box {
    background-color: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
    padding: 25px 30px;
    width: 100%;
    display: flex;
}

.profile-logo {
    max-width: 75px;
    flex: 0 0 75px;
}

.profile-info {
    padding-left: 30px;
    max-width: calc(100% - 75px);
    flex: 0 0 calc(100% - 75px);
}

.profile-main-info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-weight: 400;
}

.profile-name {
    max-width: 100%;
    flex: 0 0 100%;
}

.profile-name h3 {
    font-size: 26px;
    color: #36465B;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 10px;
}

.profile-top {
    display: flex;
    align-items: center;
}

.profile-top img {
    margin-right: 10px;
}

.profile-top span {
    font-size: 12px;
    color: #36465B;
    font-weight: 500;
}

.profile-50 {
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 25px;
}

.profile-middle label {
    color: #687484;
    font-size: 12px;
}

.profile-middle p {
    color: #36465B;
    font-size: 16px;
    margin: 5px 0;
}

.approve-reject-lr {
    display: flex;
}

.approve-reject-lr .btn:not(:last-child) {
    margin-right: 20px;
}

.covid-img img {
    max-width: 50px;
}

.pagination-block {
    display: flex;
    justify-content: center;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #687484;
    background-color: #fff;
    border: 1px solid #dee2e6;
    text-decoration: none;
    min-width: 40px;
    text-align: center;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-link:hover {
    background-color: #f5f5f5;
}

.page-link.active {
    background-color: #F15B5B;
    color: #ffffff;
}

.btn-print {
    background-color: #F15B5B;
    padding: 12px 20px !important;
    font-size: 16px;
    font-weight: 400;
}

.message-chat-main {
    position: relative;
}

.btn.btn-loadmore {
    background-color: #ff7777;
    padding: 12px 20px !important;
    font-size: 14px;
    font-weight: 400;
    position: inherit;
    /* position: absolute; */
    top: 0px;
    left: 0;
    right: 0;
    max-width: 150px;
    margin: 25px auto !important;
    opacity: 0.7;
}

.btn.btn-loadmore:hover {
    background-color: #F15B5B;
}

.message-wrapper {
    flex-wrap: wrap;
}

.message-wrapper .invoice-msg {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 15px 20px;
}

.message-wrapper .invoice-msg a {
    font-weight: 700;
    text-decoration: none;
    color: #36465B;
}

.message-wrapper .invoice-msg a:hover {
    text-decoration: none;
    color: #EE743B;
}

.message-wrapper .invoice-msg .text-B {
    font-weight: 700;
}

/* Media Query added by Sadab */
@media (max-width: 1199.98px) {
    .message-wrapper {
        max-width: 100%;
    }
}

@media (max-width: 991.98px) {
    .message-aside {
        max-width: 280px;
        flex: 0 0 280px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .message-chat-content {
        max-width: calc(100% - 280px);
        flex: 0 0 calc(100% - 280px);
        padding-right: 15px;
        padding-left: 15px;
    }

    .message-carer-list {
        margin-right: -18px;
    }

    .invoice-shift-detail-table {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 767.98px) {
    .message-wrapper {
        display: block;
    }

    .message-aside {
        max-width: 100%;
    }

    .message-chat-content {
        /* display: none; */
        max-width: 100%;
    }

    .invoice-content-heading {
        display: none;
    }

    .label-head {
        display: block;
    }

    .invoice-content-single-box {
        flex-wrap: wrap;
    }

    .invoice-content-single-box {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .carer-info,
    .invoice-content-heading label:first-child,
    .status-info,
    .invoice-content-heading label:nth-child(2),
    .period-info,
    .amount-info,
    .invoice-content-heading label:nth-child(3),
    .invoice-content-heading label:last-child {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .carer-info,
    .status-info {
        margin-bottom: 25px !important;
    }

    .amount-info,
    .carer-info {
        padding: 0 !important;
    }

    .invoice-search {
        flex-wrap: wrap;
    }

    .invoice-search select, .invoice-search input {
        max-width: 45%;
    }

    .invoice-search select, .invoice-search input, .invoice-search .btn {
        margin-top: 15px;
    }
}

@media (max-width: 575.98px) {

    .carer-info,
    .invoice-content-heading label:first-child,
    .status-info,
    .invoice-content-heading label:nth-child(2),
    .period-info,
    .amount-info,
    .invoice-content-heading label:nth-child(3),
    .invoice-content-heading label:last-child {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .period-info,
    .amount-info {
        margin-bottom: 25px !important;
    }

    .invoice-search select, .invoice-search input {
        max-width: 100%;
        margin-right: 0 !important;
    }

    .invoice-review-box {
        padding: 20px 15px;
    }

    .invoice-shift-detail-table td,
    .invoice-shift-detail-header h4,
    .from-invoice label, .from-invoice p, .to-invoice label, .to-invoice p {
        font-size: 16px;
    }

    .num-invoice span {
        font-size: 12px;
    }

    .invoice-review-main-info h4 {
        font-size: 20px;
    }

    .invoice-review-header {
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .invoice-review-header .review-carer {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
    }

    .review-head-top-rt .applicants-post {
        font-size: 14px;
    }

    .review-head-top-rt img {
        max-width: 20px;
    }

    .invoice-msg {
        align-items: flex-start;
    }

    .my-profile-box {
        padding: 15px 20px;
    }

    .profile-50 {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .approve-reject-rt {
        margin-top: 20px;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .message-chat-heading .carer-list-single {
        flex-wrap: wrap;
    }

    .message-chat-heading .carer-list-single .carer-like {
        max-width: 100%;
        flex: 0 0 100%;
        order: 1;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin-bottom: 15px;
    }

    .message-chat-heading .carer-list-single .carer-img {
        order: 2;
    }

    .message-chat-heading .carer-list-single .carer-name {
        order: 3;
    }

    .invoice-review-heading-flex {
        flex-wrap: wrap;
    }

    .invoice-review-heading-flex-rt {
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .invoice-review-heading-flex h3 {
        margin: 30px 0 20px 0;
        width: 100%;
    }

    .from-invoice, .to-invoice, .num-invoice {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
    }
}

@media (max-width: 420px) {
    .my-profile-box {
        display: block;
        padding: 25px;
    }

    .profile-info {
        padding-left: 0;
        margin-top: 15px;
    }

    .carer-img {
        margin-right: 10px;
    }

    .carer-name h4 {
        font-size: 16px;
    }

    .carer-name p {
        font-size: 12px;
    }

    .invoice-review-heading-flex {
        flex-wrap: wrap;
    }

    .invoice-review-heading-flex-rt {
        justify-content: flex-start;
    }
}

/* Media Query added by Sadab END */


/*#####################################################*/

.new-card-block {
    padding: 20px 20px 20px 65px;
}

.new-card-block input[type=text] {
    width: 100%;
    padding: 12px 20px 12px 60px;
    margin-bottom: 15px;
    display: inline-block;
    border: 1px solid #C2CAD5;
    box-sizing: border-box;
    height: 50px;
    color: rgba(54, 70, 91, 0.7);
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: none;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
}

.new-card-block input[type=text]::placeholder {
    color: rgba(54, 70, 91, 0.7);
}

.new-card-block input[type=text]:focus {
    outline: none;
}

.new-card-block label {
    display: inline-block;
    padding: 0;
    font-size: 16px;
    margin-bottom: 10px;
    color: #36465B;
    width: 100%;
}

.new-card-block-bt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.new-card-block-bt .card-col {
    flex: 0 0 50%;
    width: 50%;
}

.card-col input[type=text] {
    width: 60%;
}

.new-card-block input[type=text].new-card, .new-card-block input[type=text].edate, .new-card-block input[type=text].cvv-num {
    background-position: left 15px center;
    background-repeat: no-repeat;
}

.new-card-block input[type=text].new-card {
    background-image: url(../src/assets/images/client-view/card-2.svg);
}

.new-card-block input[type=text].edate {
    background-image: url(../src/assets/images/client-view/calendar.svg);
}

.new-card-block input[type=text].cvv-num {
    background-image: url(../src/assets/images/client-view/cvv-lock.svg);
}

/*--------------*/
.form-group {
    display: block;
    margin: 15px 0 0 0;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #C2CAD5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
}

.form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 7px;
    width: 6px;
    height: 14px;
    border: solid #EE743B;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.debit-block {
    margin: 20px;
    background-color: #F5F5F8;
    padding: 20px;
    font-size: 14px;
    color: #3B3B3B;
    font-weight: 400;
}

/*----------- account detail -----------------*/
.account-detail-wrapper {
    width: 100%;
}

.account-detail-wrapper h3 {
    font-size: 20px;
    padding-bottom: 20px;
    margin: 0 !important;
    color: rgba(54, 70, 91, 0.75);
}

.account-detail-body {
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.account-detail-footer {
    margin: 30px 0 40px 0;
}

.detail-form-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.detail-form-wrapper-lt, .detail-form-wrapper-rt {
    flex: 0 0 48%;
    width: 48%;
}

/*---------- form -----------*/
.detail-form-control {
    margin-bottom: 20px;
    font-weight: 400;
}

.detail-form-control span.img-icon {
    /* display: inline-block; */
    max-width: 110px;
    padding-left: 92px;
    height: auto;
    padding-bottom: 5px;
    position: absolute;
}

.detail-form input[type=text], .detail-form input[type=number], .detail-form input[type=date] {
    width: 100%;
    padding: 12px 15px;
    display: inline-block;
    border: 1px solid #C2CAD5;
    box-sizing: border-box;
    height: 50px;
    color: #36465B;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    outline: none !important;
    box-shadow: none !important;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.detail-form select {
    width: 100%;
    padding: 12px 15px;
    display: inline-block;
    border: 1px solid #C2CAD5;
    box-sizing: border-box;
    height: 50px;
    color: #36465B;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    outline: none !important;
    box-shadow: none !important;
}

.detail-form input[type=text]::placeholder, .detail-form input[type=number]::placeholder, .detail-form input[type=date]::placeholder, .detail-form select::placeholder {
    color: rgba(54, 70, 91, 0.5);
    font-size: 14px;
}

.detail-form input[type=text]:focus, .detail-form input[type=number]:focus, .detail-form input[type=date]:focus, .detail-form select:focus {
    outline: none;
}

.detail-form input[type=text]:read-only {
    background-color: #e9ecef;
    opacity: 1;
}

.detail-form input[type=date] {
    color: rgba(54, 70, 91, 0.7);
    font-size: 14px;
}

.detail-form label {
    display: inline-block;
    padding: 0;
    font-size: 16px;
    margin-bottom: 10px;
    color: #36465B;
    width: 100%;
}

.detail-form label span.extric {
    color: #f00;
    display: inline-block;
}

.detail-form input[type=date] {
    position: relative;
    content: "Select";
}

/* .detail-form input[type="date"]::-webkit-inner-spin-button, */
.detail-form input[type="date"]::-webkit-calendar-picker-indicator {
    /* display: none;
    -webkit-appearance: none; */
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: 50px;
    left: -20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

/*---------- form end -----------*/
.payment-status {
    margin: 70px 0;
    text-align: center;
    width: 100%;
    font-weight: 400;
}

.payment-status .successful, .payment-status .failed {
    border: 10px solid #F5F5F8;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
}

.payment-status .successful {
    background-color: #0ECBB5;
}

.payment-status .failed {
    background-color: #F87171;
}

.payment-status h2 {
    font-size: 26px;
}

.payment-status p {
    font-size: 16px;
    margin: 0 auto;
    padding-bottom: 20px;
    line-height: 1.4;
    max-width: 700px;
}

.payment-status-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.payment-status-footer .btn-success {
    padding: 14px 35px !important;
}

.payment-status-footer .btn {
    padding: 14px 25px !important;
}

/*-------------------------------- media query start -----------------------------------------*/
@media (max-width: 1200px) {
    .client-view-container, .find-carer-container {
        max-width: 100%;
    }

    .toggled nav:last-child a:last-child {
        margin-bottom: 60px;
    }

    .top-back {
        float: right;
        position: relative;
    }

    .right-content {
        background-position: right 50% top -480px;
    }
}

@media (max-width: 1024px) {
    .client-head-top {
        margin-top: 60px;
    }

    .client-view-block-footer {
        flex-wrap: wrap;
    }

    .client-view-block-footer-lt {
        width: 100%;
    }

    .client-view-block-footer-rt {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .profile main #menu {
        width: 440px;
    }

    .list-profile-info-block {
        flex-wrap: wrap;
    }

    .list-profile-care-type {
        position: inherit;
        top: 0;
        right: 0;
        padding: 0 0 20px 0;
    }

    .list-profile-rt-block {
        padding: 20px 20px 20px 0;
    }

    /* .list-profile-care-type ul{
    display: -webkit-box;
    display: -moz-box;
  } */
    .list-profile-care-type ul {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        flex-direction: inherit;
        flex-wrap: wrap;
        background: transparent;
    }

    .list-profile-care-type .available-head {
        display: inline-block;
        background-color: #fff;
        color: #3b465c;
        padding: 10px;
    }

    .list-profile-care-type ul li {
        background-color: #fff;
        color: #3b465c;
        margin: 5px 5px 10px 0px;
        padding: 10px 15px;
        max-width: 100%;
    }

    .list-profile-care-type ul li svg {
        display: none;
    }

    .find-carer-window-rt {
        flex: 1 1 auto;
        margin-left: 20px;
    }

    /* .right-content {
  background-position: right -200px top -470px;
} */
}

@media (max-width: 768px) {
    .profile main #content h1 {
        margin-top: 0;
        font-size: 30px;
    }

    /* .right-content {
    background-position: right -200px top -470px;
  } */
    .client-view-block {
        padding: 20px;
    }

    .client-view-block-head {
        flex-wrap: wrap;
    }

    .client-view-block-head-btn {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .client-view-block-head-lt {
        width: 100%;
    }

    .client-head-top .client-view-block-head-btn {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .job-detail-block-top, .job-detail-block {
        padding: 20px;
    }

    /* .job-block-rt-top, .job-block-rt-bottom{
    left: 20px;
    padding-right: 20px;
  } */
    .person-detail-row {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .person-detail-col {
        flex: 0 0 100%;
        width: 100%;
    }

    .job-block-bottom-lt, .job-block-bottom-rt, .fav-job-block-bottom-lt, .fav-job-block-bottom-rt {
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
    }

    .job-detail-block-rt {
        padding-left: 20px;
    }

    .job-block-bottom {
        flex-wrap: wrap;
    }

    .job-block-rt-top {
        min-height: 110px;
    }

    .like-img {
        justify-content: flex-start;
        padding-top: 15px;
    }

    .find-carer-window {
        flex-wrap: wrap;
    }

    .find-carer-window-lt {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 20px;
    }

    .find-carer-window-rt {
        margin-left: 0px;
    }

    .job-block-covid-bt {
        justify-content: flex-start;
        margin-top: 10px;
    }

    .card-col input[type=text] {
        width: 90%;
    }

    .from-invoice {
        max-width: 40%;
        flex: 0 0 40%;
    }

    .to-invoice {
        max-width: 20%;
        flex: 0 0 20%;
    }
}

@media (max-width: 575.98px) {
    .client-head-top {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }

    .profile main #menu {
        width: 70%;
    }

    .job-detail-block-lt, .job-detail-block-rt {
        width: 100%;
    }

    .job-detail-block-rt {
        min-height: 200px;
    }

    .job-detail-block {
        flex-wrap: wrap;
        padding: 20px;
    }

    .job-detail-block-lt img {
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
        max-height: 250px;
    }

    .whiteB-sm {
        margin-bottom: 10px;
        margin-right: 10px;
    }

    /* .job-block-rt-top, .job-block-rt-bottom{
  left: 0px;
  padding-right: 0px;
} */
    .person-detail-block {
        padding: 20px;
    }

    .person-detail-block .text-md-14 {
        min-width: 50%;
    }

    .job-detail-block-rt {
        padding-left: 0px;
    }

    .filters_input-carer .field {
        flex-wrap: wrap;
    }

    .filters_input-carer .btn.find-carer-btn {
        max-width: 100%;
    }

    .filters_input-carer input {
        border-right: solid 1px #3b465c;
        border-radius: 0 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        -ms-border-radius: 0 !important;
        -o-border-radius: 0 !important;
        margin-right: 0;
    }

    .filter-type-carer ul {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

    .filter-type-carer input[type=radio]+label {
        min-width: auto;
        margin: 0px 5px 15px 0px;
        font-size: 14px;
    }

    .list-profile {
        flex-wrap: wrap;
    }

    .list-profile-lt {
        max-width: 100%;
    }

    .list-profile-lt .profile-photo {
        max-width: 100%;
    }

    .list-profile-rt-block, .list-profile-bt {
        padding: 20px;
    }

    .list-profile-info-content {
        flex-wrap: wrap;
    }

    .list-profile-info-line {
        padding-bottom: 7px;
        border-right: 0px;
        padding-right: 0px;
        margin-left: 0px;
        width: 100%;
    }

    .client-head-top-rt {
        flex-wrap: wrap;
        justify-content: center;
    }

    .payment-wrapper .payment-block {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .payment-block-rt {
        text-align: left;
        margin-top: 20px;
    }

    .payment-wrapper .payment-block-bt {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .payment-block-bt p {
        margin: 15px 0 0 0;
    }

    .security-block {
        flex-wrap: wrap;
    }

    .security-block-lt {
        width: 100%;
        margin: 0px 42% 10px 42%;
    }

    .card-col input[type=text] {
        width: 100%;
    }

    .new-card-block-bt {
        flex-wrap: wrap;
    }

    .new-card-block-bt .card-col {
        flex: 0 0 100%;
        width: 100%;
    }

    .detail-form-wrapper {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .detail-form-wrapper-lt, .detail-form-wrapper-rt {
        flex: 0 0 100%;
        width: 100%;
    }

    .from-invoice, .to-invoice {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
    }
}


@media (max-width: 400px) {

    .client-head-top .applicants-post {
        padding-right: 10px;
        padding-bottom: 0px;
    }

    .approval-published, .approval-pending, .approval-closed {
        margin-top: 10px;
    }
}

@media (max-width: 350px) {
    .btn-block-footer {
        flex-wrap: wrap;
    }

    .job-block-bottom-rt, .person-detail-footer, .account-detail-footer, .fav-job-block-bottom-rt {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .job-detail-block-rt {
        min-height: 250px;
    }

    .job-block-btn-rt {
        margin-top: 10px;
    }

    .person-detail-block {
        padding: 20px 15px;
    }

    .filter-type-carer input[type=radio]+label {
        min-width: 65px;
        margin: 0px 5px 15px 0px;
        font-size: 14px;
    }

    .client-head-top .applicants-post {
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
    }
}

/*-------------------------------- media query end -----------------------------------------*/


/* -----------------> can start accordion style copy from here ------------------------*/

.accordion-bral {
    min-height: 0;
    min-width: 220px;
    width: 100%;
    height: 100%;
    margin: 0px !important;
    padding: 0 15px;
}

.accordion-bral .ac-label {
    padding: 5px 20px;
    position: relative;
    display: block;
    height: auto;
    cursor: pointer;
    color: #777;
    line-height: 50px;
    font-size: 19px;
    background: #fff;
    border: 1px solid #FBB157;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    border-bottom: 0px;
}

.accordion-bral .ac-label:hover {
    background: #fff;
    border: 1px solid #FBB157;
    border-bottom: 0px;
}

.accordion-bral input+.ac-label {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.accordion-bral input:checked+.ac-label,
.accordion-bral input:checked+.ac-label:active {
    background: #fff;
    border: 1px solid #FBB157;
    border-bottom: 0px;
}

.accordion-bral input.ac-input {
    display: none;
}

.accordion-bral .article {
    background: #fff;
    overflow: hidden;
    height: 0px;
    max-height: auto;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid #FBB157;
    border-top: 0px;
}

.accordion-bral .article p {
    color: #777;
    line-height: 23px;
    font-size: 14px;
    padding: 20px;
}

.accordion-bral input:checked~.article i {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.accordion-bral input:checked~.article.ac-content {
    height: auto;
}

.accordion-bral i {
    position: absolute;
    transform: translate(-30px, 0);
    margin-top: -5px;
    right: 0;
}

.accordion-bral input:checked~.ac-label i:before {
    transform: translate(2px, 0) rotate(-45deg);
}

.accordion-bral input:checked~.ac-label i:after {
    transform: translate(-2px, 0) rotate(45deg);
}

.accordion-bral i:before, .accordion-bral i:after {
    content: "";
    position: absolute;
    background-color: #818496;
    width: 3px;
    height: 9px;
    top: -3px;
}

.accordion-bral i:before {
    transform: translate(-2px, 0) rotate(-45deg);
}

.accordion-bral i:after {
    transform: translate(2px, 0) rotate(45deg);
}

ul.ac-list {
    padding-left: 40px;
    list-style-type: disc;
}


@media (max-width: 575.98px) {
    .accordion-bral .ac-label {
        font-family: Arial, sans-serif;
        padding: 5px 20px;
        position: relative;
        display: block;
        height: auto;
        padding-right: 40px;
        cursor: pointer;
        color: #777;
        line-height: 50px;
        font-size: 19px;
        background: #fff;
        border: 1px solid #FBB157;
    }

    .accordion-bral i {
        position: absolute;
        transform: translate(-30px, 0);
        margin-top: 2%;
        right: 0;
    }

    .accordion-bral i:before, .accordion-bral i:after {
        top: -8px;
    }
}

/* ---------- accordion style end --------------------------------------- */

/*-----------------------*/
.custom-radio-wrap {
    padding: 0px;
    margin-bottom: 20px;
}


.custom-radio-wrap form .form_group {
    padding: 20px 20px 20px 65px;
    border-bottom: 1px solid #DBDBDB;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

@media (max-width: 575.98px) {
    .custom-radio-wrap form .form_group, .new-card-block {
        padding: 20px;
    }
}

.custom-radio-wrap form .form_group .form_group-lt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.custom-radio-wrap form .form_group .form_group-lt .expiry {
    color: rgba(54, 70, 91, 0.7);
    font-size: 13px;
    margin-left: 35px;
}

.custom-radio-wrap form .form_group:last-child {
    margin-bottom: 0;
    border-bottom: 0px;
}

.custom-radio-wrap form .form_group label {
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #36465B;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    width: 26px;
    height: 26px;
}

.custom-radio-wrap form .form_group .label-text {
    vertical-align: middle;
    cursor: pointer;
    padding-left: 10px;
    margin-left: -5px;
    font-weight: 500;
}

.custom-radio-wrap form .form_group input {
    display: none;
    cursor: pointer;
}

.custom-radio-wrap form .form_group input:checked+label {
    background-color: #fff;
    color: #36465B;
    border: 1px solid #EE743B;
}

.custom-radio-wrap form .form_group input:checked~.label-text {
    color: #36465B;
    font-weight: 400;
}

.custom-radio-wrap form .form_group input:checked+label:after {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50px;
    position: absolute;
    top: 5px;
    left: 5px;
    background: #EE743B;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
    text-shadow: none;
    font-size: 32px;
}

/*----------------------------------*/
/* the, drop down div */



.form_group-rt .drop_down {
    position: absolute;
    right: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    outline: none;
    opacity: 0;
    z-index: -1;
    max-height: 0;
    padding: 15px;
    font-size: 14px;
    color: #36465B;
    font-weight: 400;
    text-decoration: none !important;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    -webkit-transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    -moz-transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    -ms-transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    -o-transition: opacity 0.1s, z-index 0.1s, max-height 5s;
}

.form_group-rt .drop_down a {
    font-size: 14px;
    color: #36465B;
    text-decoration: none !important;

}

.form_group-rt .drop_down-container:focus {
    outline: none;
    font-size: 14px;
    color: #36465B;
    text-decoration: none !important;
}

.form_group-rt .drop_down-container:focus .drop_down {
    opacity: 1;
    z-index: 100;
    max-height: 100vh;
    transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
    -webkit-transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
    -moz-transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
    -ms-transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
    -o-transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}

/*-------------------------------- Service Agreement ------------------------------------------*/
.service-agreement-main-content .carer-info, .service-agreement-main-content .invoice-content-heading label:first-child {
    max-width: 23%;
    flex: 0 0 23%;
}

.service-agreement-main-content .status-info, .service-agreement-main-content .invoice-content-heading label:nth-child(2) {
    max-width: 19%;
    flex: 0 0 19%;
    margin-right: 1%;
}

.service-agreement-main-content .period-info, .service-agreement-main-content .invoice-content-heading label:nth-child(3) {
    max-width: 15%;
    flex: 0 0 15%;
}

.service-agreement-main-content .amount-info, .service-agreement-main-content .invoice-content-heading label:last-child {
    max-width: 42%;
    flex: 0 0 42%;
}

.service-agreement-main-content .amount-info {
    justify-content: flex-start;
}

.service-agreement-main-content .carer-list-single .covid-img, .amount-info .covid-img {
    margin-right: 20px;
}

.service-agreement-main-content .review-btn {
    width: 220px;
    margin-right: 0px;
}

.service-agreement-main-content .review-btn.flex-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.service-agreement-main-content .amount-info .view-link {
    margin-right: 0;
}

.service-agreement-main-content .flex-btns .btn {
    margin-left: 10px;
}

.service-agreement-main-content .flex-btns .edit-review-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
    color: #E75757;
    text-decoration: none;
    margin-left: 20px;
}

.service-agreement-main-content .flex-btns .edit-review-btn i {
    margin-left: 5px;
    font-size: 22px;
}

.service-agreement-review h5 {
    margin-bottom: 0;
}

.invoice-review-box a {
    font-size: 14px;
    color: #08554E;
    text-decoration: none;
}

.invoice-healthcare,
.invoice-responsibilities {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -15px;
    margin-top: 20px;
}

.invoice-responsibilities>div {
    max-width: 25%;
    flex: 0 0 25%;
    padding: 15px;
}

.invoice-healthcare>div {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 15px;
}

.invoice-healthcare>div p,
.invoice-responsibilities>div p {
    margin: 0;
}

.invoice-rating-review {
    margin-top: 50px;
    padding: 20px 25px;
}

.invoice-rating-review-heading {
    display: flex;
    justify-content: space-between;
}

.invoice-rating-review-heading a {
    font-size: 15px;
    color: #F7941D;
    text-decoration: none;
}

.invoice-rating-review-heading h4 {
    font-size: 20px;
    color: #3B465C;
    font-weight: 500;
    margin: 0;
}

.invoice-rating-review-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
}

.invoice-rating-review-content .rating-thumb {
    max-width: 75px;
    flex: 0 0 75px;
}

.invoice-rating-review-content .rating-text {
    max-width: calc(100% - 75px);
    flex: 0 0 calc(100% - 75px);
    margin: 0;
    font-size: 14px;
    color: #151515;
    line-height: 1.5;
}

.rating-modal {
    max-width: 300px;
    padding: 22px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
}

.rating-modal h4 {
    font-size: 20px;
    color: #3B465C;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 500;
    margin-top: 0;
}

.three-rating-thumbs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.three-rating-thumbs>div {
    height: 90px;
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
    text-align: center;
    border: 1px solid #ffffff;
}

.three-rating-thumbs>div a {
    display: grid;
    text-align: center;
    height: 90px;
    background-color: #F7F7FA;
    border: 1px solid #F7F7FA;
    text-decoration: none;
    color: #000000;
    font-size: 14px;
    position: relative;
}

.three-rating-thumbs>div a span {
    font-weight: 700;
    padding: 0 5px;
}

.three-rating-thumbs>div a img {
    margin: 0 auto;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.three-rating-thumbs>div a:hover,
.three-rating-thumbs>div a.active {
    background-color: #FFF2E2;
    border: 1px solid #F7941D;
}

.three-rating-thumbs>div span {
    margin-top: 8px;
    text-decoration: none;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.rating-comment-form label {
    font-size: 13px;
    color: #6C7485;
    margin-bottom: 10px;
    display: block;
}

.rating-comment-form textarea {
    width: 100%;
    border: 1px solid #C2CAD5;
    min-height: 115px;
    margin-bottom: 30px;
}

.rating-comment-btn {
    display: flex;
    justify-content: space-between;
}

.rating-comment-btn .btn {
    background-color: #F15B5B;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 400;
}

.rating-comment-btn .btn-outline-dark {
    background-color: #ffffff;
    border: 1px solid #3B465C;
    color: #3B465C;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 1024px) {
    .service-agreement-main-content .review-btn {
        margin-top: 10px;
    }

    .invoice-responsibilities>div {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

@media (max-width: 575.98px) {

    .service-agreement-main-content .carer-info, .service-agreement-main-content .invoice-content-heading label:first-child,
    .service-agreement-main-content .status-info, .service-agreement-main-content .invoice-content-heading label:nth-child(2),
    .service-agreement-main-content .period-info, .service-agreement-main-content .invoice-content-heading label:nth-child(3),
    .service-agreement-main-content .amount-info, .service-agreement-main-content .invoice-content-heading label:last-child {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .service-agreement-main-content .review-btn.flex-btns {
        justify-content: flex-start;
    }

    .service-agreement-main-content .flex-btns .btn {
        margin-right: 10px;
        margin-left: 0;
    }

    .invoice-healthcare>div p,
    .invoice-responsibilities>div p {
        font-size: 13px;
    }

    .invoice-healthcare>div {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

/*-------------------------------- NEW PAGES HTML 05-01-2022 ------------------------------------------*/
/*-------*/
.action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.action-footer .action-left {
    display: flex;
}

.action-footer .action-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.action-left .action-footer-btn {
    background-color: #F15B5B;
    color: #fff;
    padding: 14px 20px !important;
    font-size: 16px;
    font-weight: 500;
}

.action-right .action-footer-btn {
    background-color: #eaf1fa;
    color: #000;
    padding: 14px 20px !important;
    font-size: 16px;
    margin-left: 10px;
    font-weight: 500;
}

@media (max-width: 400px) {
    .action-footer {
        flex-wrap: wrap;
    }

    .action-footer .action-right, .action-footer .action-right {
        width: 100%;
    }

    .action-footer .action-right {
        justify-content: flex-start;
        margin-top: 15px;
    }

    .action-right .action-footer-btn {
        margin-left: 0px;
        margin-right: 10px;
    }
}

.agreement-review-block .review-head-top-rt img {
    background-color: #fff;
    padding: 2px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.new-agreement-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.new-agreement-block .btn {
    padding: 10px 20px !important;
    font-weight: 400;
    background-color: #F15B5B !important;
}

.no-agreement .steps-blocks {
    display: table;
    background-color: #F5F5F8;
    color: #3B465C;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 20px;
    margin: 20px auto 0px auto;
    max-width: 400px;
    line-height: 1.4;
}

.thank-you-block .btn {
    padding: 10px 20px !important;
    font-weight: 400;
    background-color: #F15B5B !important;
    display: table !important;
    margin: 15px auto;
}

@media (max-width: 575.98px) {
    .profile main #content h1 {
        margin-bottom: 25px;
        text-align: center;
    }
}

/*--------- app block start-------*/
.app-block {
    display: flex;
    padding: 50px 0;
    position: relative;
    overflow: visible;
    align-items: center;
    justify-content: flex-end;
    height: 360px;
    background-color: #f4f7fa;
    margin: 100px 0;
    width: 100%;
}

.app-img {
    position: absolute;
    width: auto;
}

.app-block-lt {
    flex: 0 0 40%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-block-rt {
    flex: 0 0 55%;
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-block-rt-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 75%;
}

.app-text {
    font-size: 30px;
    color: #3B465C;
    padding-bottom: 20px;
    line-height: 1;
    font-weight: 400;
    text-align: left;
}

.app-icon {
    max-width: 75px;
    height: auto;
    margin-right: 20px;
}

.app-dw-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app-dw-mar {
    margin-right: 10px;
}

@media (max-width: 768px) {
    .app-img {
        width: inherit;
        min-width: 340px;
    }
}

@media (max-width: 575.98px) {
    .app-block {
        flex-wrap: wrap;
        height: auto;
        margin: 50px 0;
    }

    .app-block-lt, .app-block-rt {
        flex: 0 0 100%;
        width: 100%;
    }

    .app-img {
        top: 0;
        min-width: auto;
    }

    .app-icon {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .app-block-rt {
        padding-top: 100vw;
    }

    .app-block-rt-block {
        max-width: 90%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .app-text {
        font-size: 25px;
        text-align: center;
    }
}

/*--------- app block end-------*/
/*----------*/
.carer-profile-section .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .carer-profile-section .content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }
}

@media (min-width: 1024px) {
    .carer-profile-section .content {
        margin-top: 0;
    }
}

@media (min-width: 768px) {
    .carer-profile-information {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 44%;
        flex: 0 0 44%;
    }
}

@media (min-width: 1024px) {
    .carer-profile-information {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 31%;
        flex: 0 0 31%;
    }
}

@media (min-width: 768px) {
    .carer-profile-details {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 52%;
        flex: 0 0 52%;
    }
}

@media (min-width: 1024px) {
    .carer-profile-details {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 62%;
        flex: 0 0 62%;
    }
}

.carer-profile-details h1 {
    font-size: 25px;
    line-height: 28px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

@media (min-width: 768px) {
    .carer-profile-details h1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        margin-top: 10px;
    }
}

.carer-profile-details h1 span {
    font-size: 16px;
    line-height: 21px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
}

@media (min-width: 768px) {
    .carer-profile-details h1 span {
        margin-left: 10px;
    }
}

.carer-profile-image,
.m-carer-profile-image {
    width: 100%;
}

.carer-profile-image {
    display: none;
}

@media (min-width: 768px) {
    .carer-profile-image {
        display: block;
    }
}

.m-carer-profile-image {
    display: block;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .m-carer-profile-image {
        display: none;
    }
}

.carer-profile-information .carer-profile-basics {
    display: none;
}

@media (min-width: 768px) {
    .carer-profile-information .carer-profile-basics {
        display: block;
        margin-top: 20px;
    }
}

.carer-profile-details .carer-profile-basics {
    display: block;
}

@media (min-width: 768px) {
    .carer-profile-details .carer-profile-basics {
        display: none;
    }
}

.carer-profile-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .carer-profile-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.carer-details .btn,
.carer-profile-buttons .btn,
.carer-profile-buttons .filters-box-toggle {
    font-size: 14px;
    line-height: 34px;
    padding: 12px 20px !important;
    color: #ffffff;
    margin-right: 20px;
}

.carer-details .btn {
    width: max-content;
}

@media (min-width: 768px) {

    .carer-details .btn,
    .carer-profile-buttons .btn,
    .carer-profile-buttons .filters-box-toggle {
        font-size: 16px;
        line-height: 43px;
        color: #ffffff;
    }
}

.carer-profile-buttons .carer-shortlist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
}

@media (min-width: 768px) {
    .carer-profile-buttons .carer-shortlist {
        font-size: 16px;
        line-height: 21px;
        color: #3b465c;
    }
}

.carer-profile-buttons .carer-shortlist svg {
    width: 22px;
    height: 22px;
    margin-left: 8px;
}

@media (min-width: 768px) {
    .carer-profile-buttons .carer-shortlist svg {
        width: 28px;
        height: 28px;
    }
}

.carer-profile-summary {
    font-size: 16px;
    line-height: 21px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
}

@media (min-width: 768px) {
    .carer-profile-summary {
        font-size: 22px;
        line-height: 27px;
        color: #3b465c;
    }
}

.carer-profile-summary h5 {
    font-size: 16px;
    line-height: 21px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 600;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .carer-profile-summary h5 {
        font-size: 22px;
        line-height: 27px;
        color: #3b465c;
    }
}

.carer-profile-basics {
    background-color: #3b465c;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
}

.carer-profile-basics ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.carer-profile-basics ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 10px 0;
}

@media (min-width: 768px) {
    .carer-profile-basics ul li {
        margin: 20px 0;
    }
}

.carer-profile-basics ul li span {
    display: block;
    text-align: right;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 600;
    margin-left: 10px;
}

.carer-profile-approval {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 10px;
}

.carer-profile-approval .insured-toggle,
.carer-profile-approval .approved-toggle {
    cursor: pointer;
}

@media (min-width: 1024px) {
    .carer-profile-approval {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.video .carer-profile-information .carer-profile-approval {
    display: none;
}

@media (min-width: 768px) {
    .video .carer-profile-information .carer-profile-approval {
        display: block;
    }
}

.video .carer-profile-details .carer-profile-approval {
    display: block;
}

@media (min-width: 768px) {
    .video .carer-profile-details .carer-profile-approval {
        display: none;
    }
}

.carer-profile-approval .carer-approved,
.carer-profile-approval .carer-insured {
    display: inline-block;
    font-size: 14px;
    line-height: 21px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 600;
    padding: 10px;
    border: solid 1px #3b465c;
    width: 100%;
    margin: 5px 0;
    text-align: center;
}

.carer-profile-approval .carer-approved:hover,
.carer-profile-approval .carer-insured:hover {
    text-decoration: none;
}

@media (min-width: 480px) {

    .carer-profile-approval .carer-approved,
    .carer-profile-approval .carer-insured {
        width: auto;
    }
}

@media (min-width: 1024px) {

    .carer-profile-approval .carer-approved,
    .carer-profile-approval .carer-insured {
        width: 48%;
    }
}

.video .carer-profile-approval .carer-approved, .video .carer-profile-approval .carer-insured {
    width: auto;
}

.carer-profile-approval .carer-approved span,
.carer-profile-approval .carer-insured span {
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
    display: block;
}

@media (min-width: 480px) {

    .carer-profile-approval .carer-approved span,
    .carer-profile-approval .carer-insured span {
        display: inline-block;
        margin-left: 5px;
        padding-left: 10px;
        border-left: solid 2px #3b465c;
    }
}

.carer-profile-approval p {
    font-size: 14px;
    line-height: 18px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
}

.carer-profile-approval p a {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .carer-profile-approval p {
        font-size: 16px;
        line-height: 21px;
        color: #3b465c;
    }
}

.carer-video {
    position: relative;
    margin: 30px 0;
}

.carer-video video {
    outline: none;
    max-width: 100%;
    margin: auto;
    display: block;
}

.carer-video .video-play-button {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    border: 0;
    -webkit-appearance: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.carer-video .video-play-button svg {
    width: 75px;
    height: 75px;
}

.carer-video .video-play-button.playing:before, .carer-video .video-play-button.playing:after {
    position: absolute;
    content: '';
    display: block;
    width: 20px;
    height: 75px;
    background-color: #3b465c;
    left: 7px;
    top: 0;
}

.carer-video .video-play-button.playing:after {
    left: 47px;
}

.carer-video .video-play-button.playing svg {
    display: none;
}

.carer-details {
    padding-top: 0;
}

.carer-details h3 {
    font-size: 16px;
    line-height: 21px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .carer-details h3 {
        font-size: 20px;
        line-height: 24px;
        color: #3b465c;
        margin-top: 40px;
    }
}

.carer-details p {
    font-size: 16px;
    line-height: 21px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
}

@media (min-width: 768px) {
    .carer-details p {
        font-size: 22px;
        line-height: 27px;
        color: #3b465c;
    }
}

.carer-details .carer-features {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.carer-details .carer-features li {
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
    display: inline-block;
    background-color: #3b465c;
    padding: 5px;
    margin-top: 8px;
    margin-right: 8px;
}

@media (min-width: 768px) {
    .carer-details .carer-features li {
        font-size: 19px;
        line-height: 23px;
        color: #ffffff;
        margin-top: 14px;
        margin-right: 14px;
    }
}

.carer-details .carer-training {
    list-style-type: none;
    margin: 20px 0;
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #3b465c;
    padding: 20px;
    max-width: 600px;
}

.carer-details .carer-training li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3%;
    flex: 0 0 33.3%;
}

.carer-details .carer-training li img {
    max-width: 58px;
    display: block;
    margin: auto;
}

@media (min-width: 768px) {
    .carer-details .carer-training li img {
        max-width: 120px;
    }
}

.carer-needs-wrapper h3 {
    font-size: 22px;
    line-height: 27px;
    color: #3b465c;
    font-weight: 300;
    margin: 10px 0;
}

.carer-needs-wrapper h4 {
    font-size: 22px;
    line-height: 27px;
    font-weight: 600;
    margin: 10px 0;
}

.form-progress {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    /* margin: auto; */
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 400px;
    position: relative;
}

.form-progress:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 75%;
    left: 12.5%;
    top: 20px;
    background-color: #828282;
    z-index: 1;
}

.form-progress li {
    display: block;
    text-align: center;
    width: 33%;
    font-size: 12px;
    line-height: 14px;
    color: #3b465c;
    padding: 0 1%;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
    z-index: 1;
}

@media (min-width: 768px) {
    .form-progress li {
        font-size: 16px;
        line-height: 19px;
        color: #3b465c;
    }
}

.form-progress li span {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: solid 1px #3b465c;
    background-color: #3b465c;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: auto;
    margin-bottom: 10px;
}

.form-progress li.active span {
    background-color: #ffffff;
    border-color: #ffffff;
    font-size: 16px;
    line-height: 21px;
    color: #3b465c;
}

@media (min-width: 768px) {
    .form-progress li.active span {
        border-color: #3b465c;
    }
}

.page-section-title {
    font-size: 22px;
    line-height: 27px;
    color: #3b465c;
    margin-top: 70px;
}

.fieldset-legend {
    font-size: 22px;
    line-height: 35px;
    color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
    margin-bottom: 10px;
}

.edit-care-type {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.edit-care-type label {
    max-width: 100%;
    flex: 0 0 100%;
}

.control-label {
    font-size: 19px;
    position: relative;
    line-height: 34px;
    color: #ffffff;
    background-color: #3b465c;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 300;
    cursor: pointer;
    padding: 0 10px !important;
    margin: 8px;
    margin-left: 0;
    display: block;
}

.control-label.active {
    background-color: #fbb157 !important;
}

.control-label input.form-checkbox,
.control-label input.form-radio {
    display: none;
}

.hourly-number input, .nightly-number input, .daily-options input {
    font-size: 16px;
    line-height: 43px;
    color: #3b465c;
    width: 95px;
}

.form-number {
    display: none;
    margin-bottom: 15px;
}

.form-number.active {
    display: block !important;
}

.date-picker-enabled {
    font-size: 16px;
    line-height: 43px;
    color: #3b465c;
    width: 300px;
    padding: 0 12px;
    border: solid 2px #3b465c;
    margin-top: 5px;
}

.show-more {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3b465c;
    padding-top: 23px;
}

.spcipic-comment-label {
    display: flex;
    justify-content: space-between;
}

.spcipic-comment-label .tooltip {
    position: relative;
    display: inline-block;
    color: #ee743b;
    text-decoration: none;
    font-weight: 500;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 280px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    font-size: 12px;
    line-height: 1.6;
    right: 10px;
    top: -55px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.edit-care-type textarea {
    border: solid 1px #3b465c;
    width: 100%;
    height: 114px;
    resize: none;
    outline: none;
    font-size: 16px;
    line-height: 21px;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    color: #3b465c;
    font-weight: 300;
    padding: 5px;
    margin-top: 10px;
}

.fieldset-wrapper .btn {
    background-color: #F15B5B;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    max-width: 150px;
}

/*-------------------------------- NEW PAGES HTML 05-01-2022 END ------------------------------------------*/

/*----------------------------------- My favourite carer Unavailable --------------------------------------------*/
.carer-div {
    position: relative;
}

.disabled-carer {
    /* pointer-events: none; */
    opacity: 0.4;
}

.carer-unavailable {
    position: absolute;
    left: 0;
    top: 20px;
    width: auto;
    background-color: #D53939;
    color: #fff;
    font-size: 17px;
    padding: 10px 15px;
    font-weight: 500;
}

.carer-unavailable-msg-block {
    position: relative;
}

.carer-unavailable-msg {
    width: auto;
    display: inline-block;
    background-color: #D53939;
    color: #fff;
    font-size: 10px;
    padding: 2px 7px;
    font-weight: 500;
    position: absolute;
    top: -30px;
}

.carer-profile-section .carer-unavailable {
    top: 0 !important;
}

.micro-team-carer-unavailable {
    position: absolute;
    bottom: 0;
    width: auto;
    background-color: #D53939;
    color: #fff;
    font-size: 10px;
    padding: 2px;
    font-weight: 500;
}

/*-------------------- recomedation-review ---------------------------*/
.recomedation-review {
    width: 100%;
}

.recomedation-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 20px 0;
}

.recomedation-block h3 {
    font-size: 20px;
    font-weight: 600;
    color: #3b465c;
    margin: 0;
}

.recomedation-block .edit {
    background-color: #F15B5B;
    padding: 12px 20px !important;
    font-size: 16px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    text-decoration: none;
    margin-left: auto;
}

.review-status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 22px;
    font-weight: 600;
    color: #3b465c;
    margin-bottom: 20px;
}

.review-status img {
    margin-left: 15px;
}

.review-comment {
    font-size: 16px;
    font-weight: 300;
    color: #3b465c;
    line-height: 1.2;
    margin-bottom: 10px;
}

/*----------------------------------------------- consent form css start --------------------------------------------------------------------*/
.consent-form {
    margin-top: 30px;
}

.consent-form h4 {
    font-size: 20px;
    font-weight: 600;
    color: #3B465C;
    margin: 0 0 15px 0;
}

.consent-form p {
    font-size: 16px;
    font-weight: 300;
    color: #36465B;
    margin: 0 0 20px 0;
}

.consent-notifications {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.consent-notifications .form-group {
    margin: 0 20px 10px 0;
}

.consent-notifications .form-group label {
    margin: 10px 0 !important;
}

.consent-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px 0 20px 0;
}

.consent-info .detail-form-control {
    flex: 0 0 32%;
    width: 32%;
}

@media (max-width:767.98px) {
    .consent-info {
        flex-wrap: wrap;
    }

    .consent-info .detail-form-control {
        flex: 0 0 100%;
        width: 100%;
    }

    .carer-details-wrapper h1.align-items-center {
        margin-bottom: 25px !important;
    }
}

.consent-info .form-field label {
    width: 80px;
    font-weight: 400;
    margin-bottom: 0;
}

.consent-info .form-field input[type=text], .consent-info .form-field input[type=email], .consent-info .form-field input[type=number] {
    max-width: 300px;
    line-height: 40px;
    font-size: 14px;
}

.consent-form input[type=checkbox]+label:before {
    background-color: #fff;
}


/*----------------------------------------------- Recommend Carer css start --------------------------------------------------------------------*/

.recommend-carer-wrapper, .recommendation-details-wrapper {
    display: block;
}

.recommend-carer-wrapper .review-count, .recommend-carer-wrapper .recent-review {
    font-size: 16px;
    color: #36465B;
    margin-right: 10px;
    font-weight: 600;
}

.review-count-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.review-count-btn .view-link, .review-count-btn .btn {
    font-size: 14px !important;
    margin-left: 15px;
}

.recommend-carer-wrapper .carer-info {
    padding-left: 15px !important;
}

.recommend-carer-wrapper .carer-img {
    margin-right: 15px;
}

.recommend-carer-wrapper .amount-info .view-link {
    margin-right: 0;
}

.recommend-carer-wrapper .carer-name h4 {
    font-size: 16px;
    color: #36465B;
    font-weight: 500;
    margin-bottom: 0;
    padding-right: 10px;
}

.recommend-carer-wrapper .amount-info {
    flex-wrap: nowrap;
    padding-right: 15px !important;
}

.recommend-carer-wrapper .invoice-content-single-box.pending {
    border: 1px solid #F7941D;
    background-color: #FAF1EA;
}

.invoice-review-header {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.recommend-carer-box {
    background-color: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
    padding: 30px;
    width: 100%;
    margin-bottom: 25px;
    color: #36465B;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.recommend-carer-box h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.recommend-carer-box p {
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0 0 0;
}

.recommend-carer-box .recommend-carer-lt {
    display: block;
    width: calc(100% - 200px);
}

.recommend-carer-box .recommend-carer-rt {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 200px;
}

.recommend-carer-box .recommend-carer-rt img {
    margin-left: 15px;
}

@media (max-width:767px) {
    .recommend-carer-box {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .recommend-carer-box .recommend-carer-lt, .recommend-carer-box .recommend-carer-rt {
        width: 100%;
    }

    .recommend-carer-box .recommend-carer-rt {
        margin-top: 25px;
        justify-content: flex-start;
    }

    .recommend-carer-wrapper .amount-info {
        flex-wrap: wrap;
        padding-right: 0 !important;
    }

    .recommend-carer-wrapper .carer-info {
        padding-left: 0px !important;
    }
}

/* ------------ app remove mobile css start -------------------*/
.download-app div:first-child {
    display: none;
}

.download-app div p {
    max-width: 100% !important;
    display: flex;
    align-items: center;
}

@media (max-width: 991.98px) {
    .download-app div p {
        margin: 20px 30px;
        font-size: 30px;
    }
}

@media (max-width: 575.98px) {
    .download-app div p {
        margin: 30px 20px;
        font-size: 30px;
    }
}

@media (max-width: 560px) {
    .download-app div p {
        display: block;
    }
}

.download-app div {
    background-color: #f4f7fa;
    border-radius: 35px;
    padding: 0 50px 30px 50px !important;
    margin-top: 50px;
}

@media (max-width: 1000px) {
    .download-app div:last-child {
        padding: 0 0 20px 0 !important;
    }
}

/* ------------ app remove mobile css end -------------------*/
/*-------*/

.nav-num {
    display: inline-block;
}

.nav-num span.total-msg {
    display: inline-block;
    padding: 5px 3px;
    text-align: center;
    margin-left: 10px;
    width: 21px;
    height: 21px;
    color: #fff;
    background-color: #DC3545;
    font-size: 11px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-weight: 500;
    line-height: 1.2;
}

.nav-num span.new {
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    margin-left: 10px;
    height: 21px;
    color: #fff;
    background-color: #DC3545;
    font-size: 11px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-weight: 500;
    position: absolute;
    line-height: 1.2;
    animation: new 3s ease-in-out infinite;
    -webkit-animation: new 3s ease-in-out infinite;
}

@keyframes new {
    0% {
        left: 240px;
    }

    10% {
        left: 240px;
    }

    50% {
        left: 260px;
    }

    100% {
        left: 240px;
    }
}

/*----------------------------------- client white theme css start --------------------------------------------*/
.client-head-top h1 {
    font-weight: 700 !important;
    color: #36465B;
}

/* ----------------- Carer Details -------------------- */
.profile a.btn {
    font-weight: 500;
}

.align-items-center {
    align-items: center !important;
}

.profile .btn.red {
    background-color: #F15B5B;
}

.d-flex-ced {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bb-1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 30px;
    margin-bottom: 50px;
}

.bold-300 {
    font-weight: 300 !important;
}

.bold-400 {
    font-weight: 400 !important;
}

.bold-500 {
    font-weight: 500 !important;
}

.bold-600 {
    font-weight: 600 !important;
}

.bold-700 {
    font-weight: 700 !important;
}

/* ----------------- comman class end -------------------- */
.carer-details-wrapper {
    padding-top: 80px;
    padding-bottom: 50px;
    max-width: 1080px;
    margin-left: auto;
    font-weight: 400;
    color: #36465B;
}

.carer-details-wrapper .btn {
    background-color: #F15B5B;
    padding: 10px 20px !important;
    line-height: 1.2 !important;
}

.carer-details-wrapper .red {
    background-color: #F15B5B;
}

.carer-details-wrapper .carer-profile-basics ul li {
    font-size: 15px;
    font-weight: 400;
}

.carer-details-wrapper .carer-profile-basics ul li img {
    max-width: 64px;
    height: auto;
}

.carer-details-wrapper .carer-profile-basics {
    padding: 10px;
}

.carer-details-wrapper .location-img {
    margin-right: 3px;
}

.carer-details-wrapper h1 span {
    font-weight: 400;
}

.carer-details-wrapper h1, .carer-details-wrapper h5 {
    font-weight: 700 !important;
    font-size: 28px;
    color: #36465B;
}

.carer-details-wrapper .carer-profile-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.carer-details-wrapper .carer-profile-summary {
    font-weight: 400;
    line-height: 1.4;

}

.carer-details-wrapper .carer-profile-approval p {
    font-weight: 400;
    color: #36465B;
}

.carer-details-wrapper .carer-approved.approved-toggle, .carer-details-wrapper .carer-insured.insured-toggle {
    width: 100%;
    font-weight: 700;
    color: #36465B;
}

.carer-details-wrapper .carer-approved.approved-toggle span, .carer-details-wrapper .carer-insured.insured-toggle span {
    font-weight: 400;
    color: #36465B;
}

.carer-profile-basics-wh {
    background-color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    border: 1px solid #FBB157;
    margin-top: 20px;
}

.carer-profile-basics-wh ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.carer-profile-basics-wh ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 15px 0;
    color: #000;
    font-weight: 500;
}

.carer-profile-basics-wh ul li span {
    display: block;
    text-align: right;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 700;
    margin-left: 10px;
}

.carer-video-block {
    margin: 30px 0 50px 0;
}

.d-co {
    display: block;
}

.d-mo {
    display: none;
}

.carer-details-wrapper .carer-features {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.carer-details-wrapper .carer-features li {
    font-size: 14px;
    line-height: 1.2;
    color: #36465B;
    font-family: "Montserrat", "Helvetica, Arial, sans-serif";
    font-weight: 500;
    display: inline-block;
    background-color: #FFF5EA;
    border: 1px solid #FBB157;
    padding: 7px 10px;
    margin-top: 13px;
    margin-right: 10px;
}

.carer-details-wrapper h3 {
    font-weight: 700;
    color: #36465B;
}

p.chat {
    font-size: 15px;
    font-weight: 400;
    color: #000;
    margin: 45px 0 35px 0;
}

.carer-details-wrapper .carer-profile-image {
    position: relative;
}

.carer-details-wrapper .carer-unavailable {
    position: absolute;
    left: 0 !important;
    top: 0 !important;
}

.recommendations-wrapper {
    display: block;
}

.recommendations-wrapper h2 {
    font-size: 28px;
    color: #36465B;
    line-height: 1;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    font-weight: 700 !important;
}

.recommendations-wrapper h2 img {
    margin-right: 10px;
}

.recommendations-block {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
}

.recommendations-block p {
    font-size: 18px;
    color: #36465B;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 15px;
}

.recommendations-block-bt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.recommendations-date {
    font-size: 15px;
    color: #36465B;
    font-weight: 700;
    margin-right: 50px;
    min-width: 190px;
}

.recommendations-date span {
    color: rgba(54, 70, 91, 0.5);
    font-weight: 400;
}

.recommendations-date img {
    margin: 0 12px 2px 12px;
}

.recommendations-status {
    font-size: 15px;
    color: #049E98;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.recommendations-status img {
    margin-right: 10px;
}

a.see-more {
    color: #E75757 !important;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
}

.see-more {
    margin: 50px 0 75px 0;
}

.see-more a {
    color: #E75757 !important;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
}

.more-courses {
    margin: 0px 0 30px 0;
}

.micro-team-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-right: -7px;
    margin-left: -7px;
    max-height: 190px;
    overflow: auto;
}

.micro-team-container::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.micro-team-container::-webkit-scrollbar-track {
    background: rgba(33, 37, 41, 0);
}

.micro-team-container::-webkit-scrollbar-thumb {
    background: #ddd;
}

.micro-team-col {
    flex: 0 0 25%;
    width: 25%;
    padding-right: 7px;
    padding-left: 7px;
    position: relative;
}

.micro-team-block {
    background-color: #FFF5EA;
    border: 1px solid #FBB157;
    padding: 10px 12px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.micro-team-block-lt {
    width: calc(100% - 55px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.micro-team-block-rt {
    width: 50px;
    text-align: right;
}

.micro-team-block-rt img {
    width: 50px;
    height: auto;
}

.micro-team-photo {
    max-width: 100%;
    height: 54px;
    width: 54px;
    border-radius: 50%;
    margin-right: 10px;
}

.carer-pro-title {
    font-size: 14px;
    color: #36465B;
    line-height: 1.4;
    padding-right: 20px;
    word-break: break-all;
}

.micro-team-carer-unavailable {
    position: absolute;
    bottom: 14px;
    left: 7px;
    width: auto;
    background-color: #d53939;
    color: #fff;
    font-size: 10px;
    padding: 2px;
    font-weight: 500;
}

.cpd-courses-completed {
    display: block;
    position: relative;
    /* max-width: 1080px; */
}

.courses-completed {
    display: block;
    position: relative;
    max-width: 1080px;
}

.owl-list {
    position: relative;
}

.owl-theme .owl-nav {
    margin-top: 10px;
    display: table;
    position: absolute;
    top: -60px;
    right: -10px;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
    background: none;
    color: #D7605C;
    border: none;
    padding: 0 !important;
    font-size: 30px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #FBB157;
    color: #FFF;
    text-decoration: none;
}

.degree-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.degree-content {
    flex: 0 0 33.33%;
    width: 33.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.degree-block {
    background-color: #FFF5EA;
    border: 1px solid #FBB157;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 76px;
}

.degree-block img {
    width: 29px;
    max-width: 29px;
    height: auto;
    margin-right: 15px;
}

.degree-block span {
    font-size: 15px;
    font-weight: 500;
    color: #36465B;
    line-height: 1.2;
}

/*---------------- find a carer new design --------------*/
.find-carer-container-nd {
    display: block;
    color: #36465B;
}

.find-carer-container-nd h2 {
    font-size: 26px;
    color: #36465B;
    font-weight: 700 !important;
}

.find-carer-container-nd p {
    font-size: 16px;
    color: #36465B;
    font-weight: 500 !important;
    line-height: 1.3;
}

.find-carer-container-nd .filters_input-carer input, .find-carer-container-nd .filters_input-carer input::placeholder {
    font-size: 20px;
    color: #000;
}

.find-carer-container-nd .profile-badge-img img {
    width: 76px;
}

.find-carer-container-nd .find-carer-window-rt {
    flex: 1 1 auto;
    margin-right: 30px;
    margin-left: 0;
    order: 1;
}

.find-carer-container-nd .find-carer-window-lt {
    order: 2;
}

.find-carer-container-nd .get-started-carer h3 {
    font-weight: 700;
}

.find-carer-container-nd .get-started-carer p {
    font-weight: 400;
}

.find-carer-container-nd .get-started-carer .btn.red {
    font-weight: 500;
}

.find-carer-container-nd .lt-filters_box {
    background-color: #FFF5EA;
    border: 1px solid #FBB157;
}

.find-carer-container-nd .filter-toggle img {
    color: #36465B;
}

.find-carer-container-nd .filterbox-buttons .filter-apply {
    background-color: #F15B5B;
    color: #fff;
    font-weight: 600 !important;
    padding: 5px 20px !important;
}

.find-carer-container-nd .filterbox-buttons .filter-clear {
    background-color: transparent;
    border: 1px solid #36465B;
    color: #36465B;
    font-weight: 600 !important;
    padding: 5px 20px !important;
}

.find-carer-container-nd .filters_categories>li.active .filter-toggle img {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.find-carer-container-nd .list-profile {
    background-color: #fff;
    border: 1px solid #FBB157;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
}

.find-carer-container-nd .list-profile-options li {
    border: solid 1px #36465B;
    color: #36465B;
}

.find-carer-container-nd li.more-exp {
    background-color: #F2F5FA;
    border: 0px;
}

.find-carer-container-nd .list-profile-care-type ul li {
    color: #343F53;
    font-weight: 400;
}

.find-carer-container-nd .list-profile-care-type ul li svg {
    width: 33px;
    height: 22px;
    margin: 5px 0px 5px 5px;
}

.find-carer-container-nd .last-active, .find-carer-container-nd .list-profile-info-content, .find-carer-container-nd .list-profile-info-line {
    color: #000;
    font-weight: 400 !important;
}

.find-carer-container-nd .list-profile-rt h4, .find-carer-container-nd .list-profile-rt p {
    color: #000000;
    font-weight: 500 !important;
}

.find-carer-container-nd .list-profile-rt p {
    height: 127px;
}

.find-carer-container-nd .find-carer-footer .btn {
    font-weight: 500;
    display: inline-block;
}

.find-carer-container-nd .list-profile-care-type ul {
    background-color: #F2F5FA;
}

.find-carer-container-nd .list-profile-info-line {
    border-right: solid 1px #000000;
}

.find-carer-container-nd .list-profile-bt .view_profile {
    background-color: #f15b5b;
    color: #ffffff;
    text-decoration: none;
    padding: 5px 15px !important;
    font-weight: 600;
    line-height: 32px;
    font-size: 16px;
}

.find-carer-container-nd .filter-toggle {
    color: #36465B;
    font-weight: 400;
    font-size: 17px;
}

.find-carer-container-nd .rt-results-list .carer-unavailable {
    left: 20px;
    font-size: 16px;
    padding: 10px 11px;
}

.find-carer-container-nd .how-curam h3 {
    font-weight: 700;
}

@media (min-width: 768px) {
    .find-carer-container-nd .filter-toggle {
        color: #36465B;
        font-weight: 400;
        font-size: 17px;
    }

    .find-carer-container-nd .find-carer-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 25px 0;
    }
}

/*--------- My Favourite carer new design--------*/
.favourite-carer-container-nd {
    display: block;
}

.favourite-carer-container-nd .job-detail-block {
    background-color: #fff;
    border: 1px solid #FBB157;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
}

.favourite-carer-container-nd .job-block-info, .favourite-carer-container-nd .job-block-info-line, .favourite-carer-container-nd .job-block-rt-top p {
    color: #000;
    font-weight: 400;
}

.favourite-carer-container-nd .job-block-info-line {
    border-right: 1px solid #333
}

.favourite-carer-container-nd .whiteB-sm {
    color: #36465B;
    border: 1px solid #36465B !important;
    font-weight: 500;
}

.favourite-carer-container-nd .carer-unavailable {
    left: 30px;
    top: 20px;
}

.redB-btn-lg {
    border: 1px solid #F15B5B !important;
    padding: 8px 20px !important;
    color: #F15B5B !important;
    text-decoration: none;
    font-size: 14px;
    margin-right: 8px;
    font-weight: 500;
}

.favourite-carer-container-nd .fav-job-block-bottom-rt {
    width: 290px;
}

/*----------------------------- Job details new design css -----------------------------------*/
.client-head-top .job-details-nd .applicants-post {
    font-weight: 500;
}

.job-details-nd .job-detail-block-top {
    background-color: #FFF5EA;
    border: 1px solid #FBB157;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    color: #36465B;
}

.job-details-nd .job-detail-block-top h2 {
    color: #36465B;
    font-weight: 700 !important;
}

.job-details-nd .whiteB-btn-sm {
    color: #3B465C !important;
    border: 1px solid #3B465C !important;
    font-weight: 500 !important;
}

.job-details-nd .applicants-location {
    color: #36465B;
    font-size: 14px;
    font-weight: 600;
}

/* .job-details-nd .btn-block-footer{
  justify-content: space-between;
} */
.job-details-nd .btn-block-footer .btn {
    font-weight: 500;
}

.job-details-nd .btn-block-footer .blackB-btn-lg {
    padding: 8px 20px !important;
    font-size: 14px;
}

.job-details-nd .btn-block {
    margin-bottom: 30px;
}

.job-details-nd .job-detail-block {
    background-color: #fff;
    border: 1px solid #FBB157;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    color: #36465B;
}

.job-details-nd .job-block-info, .job-details-nd .job-block-info-line, .job-details-nd .job-block-rt-top p {
    color: #000;
}

.job-details-nd .job-block-info-line {
    border-right: 1px solid #000;
    font-weight: 400;
}

.job-details-nd .job-block-info-line:last-child {
    border-right: 0px;
}

.job-details-nd .job-block-info-line:first-child {
    margin-left: 0px;
}

.job-details-nd .job-block-rt-top p {
    font-weight: 400;
}

.job-details-nd .whiteB-sm {
    color: #36465B;
    border: 1px solid #36465B !important;
    font-weight: 500;
}

.job-details-nd .person-detail-wrapper h2 {
    font-weight: 700 !important;
}

.job-details-nd .curam-job-table table td, .job-details-nd .person-detail-block .text-md {
    font-weight: 700;
}

/*---------------- Payment status new design --------------*/
.payment-status-nd .payment-status h2 {
    font-size: 24px;
    font-weight: 700 !important;
    color: #36465B;
}

.payment-status-nd .payment-status p {
    color: #151515;
}

.payment-status-nd .payment-status p a {
    color: #151515;
    text-decoration: underline;
}

.payment-status-nd .blackB-btn-lg {
    margin-right: 20px;
}


/*------------------------------------------------------------------------------------------------------------------*/
@media (max-width:1366px) {
    .courses-completed {
        max-width: 860px;
    }
}

@media (max-width:1023px) {
    .carer-details-wrapper .carer-profile-buttons {
        flex-wrap: wrap;
    }

    .carer-details-wrapper .carer-profile-buttons span {
        margin: 10px 0;
    }

    .micro-team-col {
        flex: 0 0 33.33%;
        width: 33.33%;
    }

    .degree-content {
        flex: 0 0 50%;
        width: 50%;
    }
}

@media (max-width:767.98px) {
    .carer-details-wrapper .carer-unavailable {
        top: 53px !important;
    }

    .carer-details-wrapper .carer-profile-details h1 {
        justify-content: flex-start;
    }

    .carer-details-wrapper h1 span {
        margin-left: 15px;
    }
}

@media (max-width:768px) {
    .micro-team-col {
        flex: 0 0 50%;
        width: 50%;
    }

    .find-carer-container-nd .find-carer-window-rt {
        margin-right: 0px;
        order: 2;
    }

    .find-carer-container-nd .find-carer-window-lt {
        order: 1;
    }

    .favourite-carer-container-nd .carer-unavailable {
        left: 20px;
        top: 20px;
    }
}

@media (max-width: 767px) {
    .d-co {
        display: none;
    }

    .d-mo {
        display: block;
    }

    .carer-details-wrapper .carer-profile-image {
        margin-top: 15px;
    }
}

@media (max-width:670px) {
    .micro-team-col {
        flex: 0 0 100%;
        width: 100%;
    }

    .payment-status-nd .payment-status {
        margin: 0 0 50px 0;
    }
}

@media (max-width: 575.98px) {
    .find-carer-container-nd .list-profile-info-line {
        padding-bottom: 7px;
        border-right: 0px;
        padding-right: 0px;
        margin-left: 0px;
        width: 100%;
    }

    .job-details-nd .btn-block {
        flex-wrap: wrap;
    }

    .degree-content {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 15px;
    }

    .recommendations-block-bt {
        flex-wrap: wrap;
    }

    .recommendations-date, .recommendations-status {
        margin-bottom: 10px;
    }
}

/*------------- client dashboard --------------------*/
.profile main #content .tiles>a.orange.new-applicant, .profile main #content .tiles>a.orange.applicant-get-started {
    padding: 20px;
    background-color: #FFF5EA;
    border: 1px solid #FBB157;
    text-decoration: none;
    color: #3B465C;
    flex: 0 0 calc(100% - 57% - 40px);
}

.new-applicant h2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 25px;
    font-weight: 600 !important;
    margin: 0 0 15px 0;
}

.applicant-get-started h2 {
    font-size: 25px;
    font-weight: 600 !important;
    margin: 0 0 15px 0;
}

p.new-applicant-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600 !important;
    margin-bottom: 10px;
}

.new-applicant-head span {
    width: calc(100% - 35px);
}

.new-applicant-head img {
    max-width: 20px;
    height: auto;
    margin-left: 15px;
}

.total-applicants {
    display: inline-block;
    padding: 5px 3px;
    text-align: center;
    margin-right: 10px;
    width: 21px;
    height: 21px;
    color: #fff;
    background-color: #DC3545;
    font-size: 11px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-weight: 500;
    line-height: 1.2;
}

.applicant-wrapper, .applicant-get-started-wrapper {
    display: block;
}

.applicant-block {
    border-top: 1px solid #ccc;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.applicant-block:last-child {
    padding: 15px 0 0 0;
}

.applicant-block-lt {
    width: 75px;
}

.applicant-block-lt img {
    max-width: 60px;
    width: 60px;
    height: auto;
    max-height: 60px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-right: 15px;
}

.applicant-block-rt {
    width: calc(100% - 75px);
}

.applicant-block .job-block-info {
    color: #F79B2E;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 5px;
}

.applicant-block .job-block-info-content {
    padding-bottom: 0;
}

.applicant-block .job-block-info-line {
    color: #3B465C;
    border-right: 1px solid #3B465C;
    font-weight: 500;
}

.applicant-block .job-block-info-line:last-child {
    border-right: 0px;
}

/***************/
.applicant-get-started-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF5EA;
    border: 1px solid #FBB157;
    margin-bottom: 8px;
    padding: 15px;
}

.applicant-get-started-block:last-child {
    margin-bottom: 0;
}

.applicant-get-started-block-lt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 60px);
}

.applicant-get-started-block-lt span {
    color: #3B465C;
    font-size: 18px;
    font-weight: 700;
    margin-left: 15px;
}

.applicant-get-started-block-rt {
    width: 60px;
    text-align: right;
}

/*******************/

@media (max-width: 1024px) {
    .applicant-block .job-block-info-content {
        flex-wrap: wrap;
    }

    .applicant-block .job-block-info-line {
        border-right: 0px solid #3B465C;
        margin-left: 0px;
    }
}

@media (max-width: 991.98px) {
    .applicant-block .job-block-info-content {
        flex-wrap: nowrap;
    }

    .applicant-block .job-block-info-line {
        border-right: 1px solid #3B465C;
        margin-left: 10px;
    }

    .applicant-block .job-block-info-line:first-child {
        margin-left: 0;
    }
}

@media (max-width: 980px) {
    .profile main #content .tiles>a.orange.new-applicant, .profile main #content .tiles>a.orange.applicant-get-started {
        margin-bottom: 40px;
    }
}

@media (max-width: 410px) {
    .applicant-block .job-block-info-content {
        flex-wrap: wrap;
    }

    .applicant-block .job-block-info-line {
        border-right: 0px solid #3B465C;
        margin-left: 0px;
    }
}

/*------- New Search css for carer list start -------*/
.search-wrapper {
    position: relative;
    width: 100%;
}

.search-wrapper .search-icon {
    position: absolute;
    left: 63%;
    top: 25%;
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
}

/* @media (max-width: 1200px){
    .client_dashboard.profile main #content {
        padding: 100px 40px 40px 40px;
    }
    .client_dashboard.profile main #content h1{
      padding-bottom: 0;
      margin-bottom: 0;
    }
      } */

.profile main #content h1.dy-dashboard-title {
    font-size: 38px;
    text-align: left;
    margin-bottom: 30px;
    color: #3B465C;
    font-weight: 700;
}

@media (max-width:1200px) {
    .profile main #content h1.dy-dashboard-title {
        font-size: 30px;
        margin-top: 65px;
    }
}

/* ----------------- Dynamic Dashboard CSS Start 15th July 2022 ------------------- */

.dy-dashboard {
    display: block;
}

.dy-dashboard h3 {
    font-size: 20px;
    font-weight: 700;
    color: #3B465C;
    margin: 0 0 15px 0;
    line-height: 1.2;
}

.dashboard-started {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
}

.dashboard-started-col {
    flex: 0 0 32%;
    width: 32%;
    margin-bottom: 20px;
}

.dashboard-started-col-lt, .dashboard-started-col-md, .dashboard-started-col-rt {
    width: 100%;
    display: block;
}

.dashboard-started-col-md {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.dashboard-started-md-col {
    flex: 0 0 48%;
    width: 48%;
    background-color: #fff;
    border: 1px solid #CAD1DE;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 150px;
}

.dashboard-started-md-col h5 {
    font-size: 15px;
    font-weight: 800;
    color: #3B465C;
    margin: 0 0 30px 0;
}

.dashboard-started-col-rt {
    background-color: #fff;
    border: 1px solid #CAD1DE;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    padding: 15px;
    min-height: 150px;
}

.dashboard-started-col-rt .dashboard-carer-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 10%);
}

.dashboard-started-col-rt .dashboard-carer-info:first-child {
    padding: 0 0 10px 0;
}

.dashboard-started-col-rt .dashboard-carer-info:last-child {
    border-bottom: 0px;
    padding: 10px 0 0 0;
}

.dashboard-started-col-rt .dashboard-carer-info span {
    font-size: 14px;
    font-weight: 700;
    color: #3B465C;
    margin: 0 15px 0 0;
    white-space: nowrap;
}

.dashboard-started-col-rt .dashboard-carer-info p {
    font-size: 12px;
    font-weight: 500;
    color: rgb(59, 70, 92, 70%);
    margin: 0;
}

.dashboard-carer-near {
    display: block;
}

.dashboard-carer-near-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -15px;
}

.dashboard-carer-near-head a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.dashboard-carer-near-head a span {
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
    color: #E75757;
    margin-right: 15px;
}

.dashboard-carer-near-content {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    flex-wrap: nowrap;
    scrollbar-width: thin;
    scrollbar-color: #666b7a rgba(33, 37, 41, 0);
    margin-left: -10px;
    margin-right: -10px;
    width: auto;
}

.dashboard-carer-near-content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.dashboard-carer-near-content::-webkit-scrollbar-track {
    background: rgba(33, 37, 41, 0);
}

.dashboard-carer-near-content::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
}

.dashboard-carer-near-content::-webkit-scrollbar-track, .dashboard-carer-near-content::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.dashboard-carer-near-col {
    flex: 0 0 33.33%;
    width: 33.33%;
    padding-left: 10px;
    padding-right: 10px;
}

.dashboard-carer-near-block {
    width: 100%;
    margin: 15px 0 15px 0;
    display: block;
    background-color: #fff;
    border: 1px solid #CAD1DE;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    padding: 15px;
}

.dashboard-carer-near-block:last-child {
    margin: 15px 0 15px 0;
}

.dashboard-carer-near-block .applicant-block {
    border-top: 0px;
    padding: 0;
}

.dashboard-carer-near-block .applicant-block .job-block-info {
    font-size: 19px;
    color: #F7941D;
    padding-bottom: 8px;
}

.dashboard-carer-near-block .job-block-info-line {
    font-size: 14px;
    line-height: 1;
}

.dashboard-carer-near-block .dashboard-carer-near-block-bt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.carer-near-rate {
    font-size: 12px;
    font-weight: 500;
    color: #F7941D;
    padding: 5px 10px;
    border: 1px solid #F7941D;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.dy-dashboard-app {
    margin: 50px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    width: 100%;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dy-dashboard-app-lt {
    text-align: left;
    flex: 0 0 50%;
    width: 50%;
}

.dy-dashboard-app-lt h4 {
    font-size: 24px;
    color: #36465B;
    font-weight: 700;
    margin: 0;
    line-height: 1.4;
}

.dy-dashboard-app-lt p {
    font-size: 18px;
    color: rgba(54, 70, 91, 70%);
    font-weight: 600;
    margin: 0;
    line-height: 1.4;
}

.dy-dashboard-app-rt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 50%;
    width: 50%;
}

.dy-dashboard-app-rt img {
    height: 45px;
    width: auto;
    max-width: 100%;
}

.dy-dashboard-app-rt img {
    margin-left: 15px;
}

@media (max-width:1280px) {
    .dashboard-started {
        flex-wrap: wrap;
    }

    .dashboard-carer-near-content {
        width: 100%;
    }

    .dashboard-carer-near-col {
        flex: 0 0 47%;
        width: 47%;
    }

    .dashboard-carer-near-col:last-child {
        padding-right: 0px;
    }

    .dashboard-started-col {
        flex: 0 0 48.4%;
        width: 48.4%;
    }
}

@media (max-width:1200px) {
    .dashboard-carer-near-content::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0);
    }
}

@media (max-width:991.98px) {
    .dashboard-carer-near-col {
        flex: 0 0 49%;
        width: 49%;
    }

    .dy-dashboard-app {
        flex-wrap: wrap;
        justify-content: center;
    }

    .dy-dashboard-app-lt, .dy-dashboard-app-rt {
        flex: 0 0 100%;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .dy-dashboard-app-rt img {
        margin-top: 20px;
        margin-left: 8px;
        margin-right: 8px;
    }
}

@media (max-width:768px) {
    .dashboard-carer-near-block, .dashboard-started-col {
        flex: 0 0 100%;
        width: 100%;
    }

    .dashboard-carer-near-col {
        flex: 0 0 55%;
        width: 55%;
    }
}

@media (max-width:575.98px) {
    .dashboard-carer-near-col {
        flex: 0 0 90%;
        width: 90%;
    }

    .dashboard-carer-near-block .job-block-info-line {
        font-size: 13px;
    }

    .dy-dashboard-app {
        padding: 50px 22px;
    }

    .dy-dashboard-app-rt {
        justify-content: center;
    }
}

/*------------------- Family Circle css START - 28July 2022 ---------------------------------*/

.tabbed {
    overflow-x: hidden;
    /* so we could easily hide the radio inputs */
    margin: 0 0 30px 0;
}

.tabbed [type="radio"] {
    /* hiding the inputs */
    display: none;
}

.tabs {
    display: flex;
    align-items: stretch;
    list-style: none;
    padding: 0;
    margin: 0;
}

.tab>label {
    display: block;
    margin-bottom: -1px;
    padding: 15px 20px;
    border-bottom: 4px solid #fff;
    background: #fff;
    color: #3B465C;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;

}

.tab:hover label {
    border-top-color: #333;
    color: #333;
}

.tab-content {
    display: none;
    color: #777;
}

/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type="radio"]:nth-of-type(1):checked~.tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked~.tabs .tab:nth-of-type(2) label {
    background: #fff;
    border-bottom: 4px solid #F7941D;
    color: #3B465C;
}

.tabbed [type="radio"]:nth-of-type(1):checked~.tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked~.tab-content:nth-of-type(2) {
    display: block;
}

.tab-col {
    background-color: #fff;
    padding: 25px 20px;
}

.circle-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0;
    position: relative;
    border-bottom: 1px solid #E2E2E2;
}

.circle-list-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 20px);
}

.circle-list-info-lt {
    width: 80px;
    margin-right: 20px;
}

.circle-list-info-lt .circle-user {
    background-image: url(../src/assets/images/client-view/circle-bg.svg);
    background-position: left center;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    max-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
}

.circle-list-info-rt {
    width: calc(100% - 100px);
}

.circle-list-info-rt h4 {
    font-size: 24px;
    font-weight: 600;
    color: #3B465C;
    margin: 0 0 5px 0;
    line-height: 1.2;
}

.circle-list-info-rt p {
    font-size: 14px;
    font-weight: 500;
    color: rgb(59, 70, 92, 75%);
    margin: 0;
    line-height: 1.2;
}

.circle-list-info-rt p.f-bold {
    font-weight: 600;
}

.circle-list-btn {
    display: block;
    width: 20px;
}

/* the, drop down div */

.circle-list-btn .drop_down {
    position: absolute;
    right: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
    outline: none;
    opacity: 0;
    z-index: -1;
    max-height: 0;
    padding: 15px;
    font-size: 14px;
    color: #36465B;
    font-weight: 400;
    text-decoration: none !important;
    transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    -webkit-transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    -moz-transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    -ms-transition: opacity 0.1s, z-index 0.1s, max-height 5s;
    -o-transition: opacity 0.1s, z-index 0.1s, max-height 5s;
}

.circle-list-btn .drop_down a {
    font-size: 14px;
    color: #36465B;
    text-decoration: none !important;

}

.circle-list-btn .drop_down-container:focus {
    outline: none;
    font-size: 14px;
    color: #36465B;
    text-decoration: none !important;
}

.circle-list-btn .drop_down-container:focus .drop_down {
    opacity: 1;
    z-index: 100;
    max-height: 100vh;
    transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
    -webkit-transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
    -moz-transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
    -ms-transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
    -o-transition: opacity 0.2s, z-index 0.2s, max-height 0.2s;
}

/* the, drop down div end */

.manage-requests {
    display: block;
}

.manage-requests p.msg {
    font-size: 14px;
    color: rgb(59, 70, 92, 75%);
    margin: 0 0 10px 0;
    font-weight: 400;
}

.manage-requests .circle-list-info {
    width: 100%;
}

.manage-requests .circle-list-info {
    align-items: flex-start;
}

.manage-requests .circle-list-info-rt {
    margin-top: 15px;
}

.manage-requests .btns-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.manage-requests .btn-lg, .manage-requests .btn-sm {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btns-block .org {
    background-color: #F7941D;
}

.btns-block .red {
    background-color: #F15B5B;
}

.btns-block .btn-sm {
    margin-left: 10px;
}

.btns-block .btn-sm:first-child {
    margin-left: 0px;
}

.manage-requests .btn-lg {
    padding: 15px 30px;
}

.manage-requests .btn-sm {
    padding: 15px;
    min-width: 100px;
}

.manage-requests .circle-list-info-rt p {
    margin: 0 0 20px 0;
}

.member-not-found {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 50px;
    align-items: center;
    justify-content: center;
}

.member-not-found p {
    font-size: 14px;
    font-weight: 500;
    color: rgb(59, 70, 92, 75%);
    margin: 0 0 20px 0;
    line-height: 1.2;
}

.member-not-found .btn.red:hover {
    background-color: #F15B5B;
}

.family-circle-modal {
    width: 60%;
    margin: 0 auto;
    text-align: left;
}

.family-circle-modal h4 {
    font-size: 18px;
    font-weight: 500;
    color: #3B465C;
    margin: 30px 0;
}

.family-circle-modal p {
    font-size: 15px;
    font-weight: 400;
    color: #3B465C;
    margin: 30px 0;
}

.family-circle-modal .form-group label svg {
    display: none;
}

.invite-options-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.invite-options-block {
    background-color: #F7F7FA;
    border: 1px solid #F7F7FA;
    padding: 20px;
    margin-right: 15px;
}

.invite-options-block.active {
    background-color: #FFF2E2;
    border: 1px solid #F7941D;
}

.invite-options-block .form-group {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.invite-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.invite-option img {
    margin-right: 10px;
}

.invite-options-block .form-group label:before {
    border: 1px solid #DADADE;
    background-color: #fff;
}

.invite-options-block .form-group input:checked+label:before {
    border: 1px solid #F7941D;
    background-color: #F7941D;
}

.invite-options-block .form-group input:checked+label:after {
    border: solid #fff;
    top: 2px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.invite-options-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 40px 0;
    padding-top: 30px;
    border-top: 1px solid #ccc;
}

/*------------------*/
.search-family-circle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #F5F5F8;
}

.find-from-contacts {
    background-color: #F15B5B;
    padding: 16px 20px 15px 20px !important;
    font-size: 16px;
}

.search-family-circle form {
    color: #555;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2px;
    border: 1px solid currentColor;
    border-radius: 5px;
    width: calc(100% - 110px);
}

.search-family-circle input[type="search"] {
    width: 100%;
    padding: 12px 20px 12px 60px;
    display: inline-block;
    border: 1px solid #C2CAD5;
    box-sizing: border-box;
    height: 50px;
    color: rgba(54, 70, 91, 0.7);
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: none;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
}

.search-family-circle input[type="search"]::-webkit-input-placeholder {
    color: rgba(54, 70, 91, 0.5);
    font-size: 14px;
}

.search-family-circle input[type="search"]:-ms-input-placeholder {
    color: rgba(54, 70, 91, 0.5);
    font-size: 14px;
}

.search-family-circle input[type="search"]::-ms-input-placeholder {
    color: rgba(54, 70, 91, 0.5);
    font-size: 14px;
}

.search-family-circle input[type="search"]::placeholder {
    color: rgba(54, 70, 91, 0.5);
    font-size: 14px;
}

.search-family-circle button[type="submit"] {
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    cursor: pointer;
    opacity: 1;
}

.search-family-circle button[type="submit"]:hover {
    opacity: 1;
}

.search-family-circle button[type="submit"]:focus,
.search-family-circle input[type="search"]:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.search-family-circle form.nosubmit {
    border: none;
    padding: 0;
}

.search-family-circle input.nosubmit {
    border: 1px solid #555;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent url(../src/assets/images/client-view/search-org.svg) center left 10px no-repeat;
}

.tab-num {
    position: relative;
}

.tab-num span.total-num {
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    max-width: 21px;
    color: #fff;
    background-color: #DC3545;
    font-size: 11px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-weight: 500;
    line-height: 1.2;
}

/*----------- @media for Family Circle*/
@media (max-width:991.98px) {
    .search-family-circle form {
        width: calc(100% - 110px);
    }
}

@media (max-width:670px) {
    .search-family-circle {
        flex-wrap: wrap;
    }

    .search-family-circle form {
        width: 100%;
    }

    .find-from-contacts {
        margin-top: 10px;
    }
}

@media (max-width:575.98px) {
    .tab-col {
        padding: 25px 5px;
    }

    .circle-list-info-rt h4 {
        font-size: 20px;
    }

    .circle-list-info-lt {
        margin-right: 10px;
    }

    .circle-list-info-rt {
        width: calc(100% - 90px);
    }

}


/*------------------- Family Circle css START---------------------------------*/